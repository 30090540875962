/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable  no-lone-blocks */
/* eslint-disable  no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable no-unsafe-optional-chaining */

import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { useTable, useExpanded, useGlobalFilter } from 'react-table';
import classes from '../../components/assetsTable/assetsTable.module.css';
import EpqFilter from './epqFilter';
import BOQFilter from './BOQFilter';
import BOSFilter from './BOSFilter';
import HistoryFilter from './HistoryFilter';

function EpqTable({
  getSelectedRowwValues,
  iSShowFilter,
  setISShowFilter,
  isShowMenu,
  setIsShowMenu,
  setIsLoading,
  getDataRows,
  data,
  columns,
  fromBillPopup,
  SORTable,
}) {
  const { SORTab } = useSelector((state) => state.SOR);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, allColumns } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    useGlobalFilter,
    useExpanded,
  );
  const [filteredCol, setFilteredCol] = useState(allColumns);

  useEffect(() => {
    setFilteredCol(allColumns.filter((item) => item.id !== 'checkbox'));
  }, [allColumns]);

  return (
    <>
      {SORTab === 'sor' && (
        <EpqFilter
          fromBillPopup={fromBillPopup}
          getDataRows={getDataRows}
          setISShowFilter={setISShowFilter}
          iSShowFilter={iSShowFilter}
          setIsShowMenu={setIsShowMenu}
          isShowMenu={isShowMenu}
          allColumns={filteredCol}
          setIsLoading={setIsLoading}
        />
      )}
      {SORTab === 'boq' && (
        <BOQFilter
          getDataRows={getDataRows}
          setISShowFilter={setISShowFilter}
          iSShowFilter={iSShowFilter}
          allColumns={filteredCol}
          setIsLoading={setIsLoading}
        />
      )}
      {SORTab === 'bos' && (
        <BOSFilter
          getDataRows={getDataRows}
          setISShowFilter={setISShowFilter}
          iSShowFilter={iSShowFilter}
          allColumns={filteredCol}
          setIsLoading={setIsLoading}
        />
      )}
      {SORTab === 'history' && (
        <HistoryFilter
          fromBillPopup={fromBillPopup}
          getDataRows={getDataRows}
          setISShowFilter={setISShowFilter}
          iSShowFilter={iSShowFilter}
          allColumns={allColumns}
          setIsLoading={setIsLoading}
        />
      )}
      <div className={classes.scrollX}>
        <table
          {...getTableProps()}
          className={classes.table}
          style={SORTable || fromBillPopup ? { whiteSpace: 'wrap' } : {}}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column?.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length !== 0 ? (
              rows?.map((row) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      const styles = {};
                      if (cell.column?.Header === 'Source') {
                        styles.whiteSpace = 'nowrap';
                      }
                      if (cell.column?.Header === 'Description') {
                        styles.maxWidth = '300px';
                        styles.minWidth = '250px';
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          onClick={() =>
                            getSelectedRowwValues ? getSelectedRowwValues(row.original, cell) : ''
                          }
                          style={styles}
                        >
                          {cell.column.Header === 'Activity' && row.original?.activity !== null ? (
                            <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                              {parse(row?.original?.activity)}
                            </p>
                          ) : (
                            cell.render('Cell')
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                {' '}
                <td className={classes.noResults} colSpan="8">
                  No Results Found{' '}
                </td>{' '}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default EpqTable;
