/* eslint-disable no-unreachable */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { format, parseISO, setHours, setMinutes, setSeconds } from 'date-fns';

import FormSurveySecureAccess from './components/SubmitForm/FormSurveySecureAccess';
import Button from '../../components/button/button';
import { FormType } from './config/forms/constant';
import {
  createFormResponseById,
  getFormDetailByIdWithoutAuth,
  getFormResponseDetailById,
  updateFormResponseById,
  validateForm,
} from '../../redux/features/forms/formSlice';
import Loader from '../../components/loader/loader';
import FormRequestSecureAccess from './components/SubmitForm/FormRequestSecureAccess';
import { Form } from '../../components/form/form';
import { generateSubmitFormValidationSchema } from './utils/schema';
import FormRequestSignature from './components/SubmitForm/FormRequestSignature';
import FormRequestEnding from './components/SubmitForm/FormRequestEnding';
import FormSurveySecureAccessPart2 from './components/SubmitForm/FormSurveySecureAccessPart2';
import FormSubmitForm from './components/SubmitForm/FormSubmitForm';
import FormSurveyEnding from './components/SubmitForm/FormSurveyEnding';
import FormSetDueDate from './components/SubmitForm/FormSetDueDate';
import NotFound from '../../components/notFounPage/notFound';

export default function FormSubmitFormDetail() {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isEdit = queryParams.get('isEdit') === 'true';
  const responseId = queryParams.get('responseId');

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    form: formDetail,
    isFormDetailGetLoading,
    formResponseDetail,
  } = useSelector((state) => state.form);

  const formSections = useMemo(() => {
    return formDetail?.sections?.map((section) => {
      return {
        id: Math.random(),
        title: section.title,
        description: section.description,
        items: section.fields?.map((item) => {
          return {
            id: Math.random(),
            question: item.label,
            answer: item.field_type,
            content: item.options
              ? JSON.parse(item.options).map((option) => {
                  return {
                    id: option.id,
                    text: option.text,
                    type: option.type || undefined,
                  };
                })
              : undefined,
            isRequired: item.is_required,
          };
        }),
      };
    });
  }, [formDetail]);

  const schema = formSections ? generateSubmitFormValidationSchema(formSections) : undefined;

  const form = useForm({
    mode: 'onChange',
    resolver: schema ? yupResolver(schema) : undefined,
  });

  const dispatch = useDispatch();

  const isSurveyForm = formDetail?.form_type === FormType.Survey;

  useEffect(() => {
    dispatch(getFormDetailByIdWithoutAuth(id));
  }, []);

  useEffect(() => {
    if (isEdit && responseId) {
      dispatch(getFormResponseDetailById(responseId));
    }
  }, [isEdit, responseId]);

  useEffect(() => {
    if (formResponseDetail && isEdit) {
      const formResponse = formResponseDetail?.section_responses?.map((section) => {
        const fields = section?.field_responses?.reduce((acc, field) => {
          acc[field.field_label] = field.value;
          return acc;
        }, {});

        return fields;
      });

      form.reset({
        forms: formResponse,
        start_date: formResponseDetail?.start_time,
        end_date: formResponseDetail?.end_time,
        start_time: formResponseDetail?.start_time
          ? format(new Date(formResponseDetail?.start_time), 'HH:mm')
          : '',
        end_time: formResponseDetail?.end_time
          ? format(new Date(formResponseDetail?.end_time), 'HH:mm')
          : '',
      });
    }
  }, [formResponseDetail]);

  useEffect(() => {
    form.reset({
      form_type: formDetail?.form_type,
      isDueDate: formDetail?.is_due_date,
    });
  }, [formDetail]);

  if (isFormDetailGetLoading) {
    return <Loader />;
  }

  const isDueDate = formDetail?.is_due_date;

  const handleSecureAccess = async () => {
    const isValid = await form.trigger(['email', 'password']);
    if (isValid) {
      try {
        if (isEdit) {
          const email = form.watch('email');
          const submittedEmail = formResponseDetail?.submitted_by.email;

          if (email !== submittedEmail) {
            toast.error('You are not authorized to edit this form');
            return;
          }
        }

        const response = await dispatch(
          validateForm({ email: form.watch().email, user_password: form.watch().password, id }),
        );

        localStorage.setItem(
          'userToken',
          JSON.stringify({
            access: response.payload.access_token,
            refresh: response.payload.refresh_token,
          }),
        );

        unwrapResult(response);
        setCurrentIndex((prev) => prev + 1);

        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 10);
      } catch (error) {
        toast.error('Invalid email or password');
      }
    }
  };

  const handleFinishFillForm = async () => {
    await form.trigger(['forms']);
    const allErrors = form.formState.errors;

    const isErrorValid = allErrors.forms?.some((item, index) => {
      const formErrorKeys = Object.keys(item);
      const formValuesKeys = Object.keys(form.watch().forms[index]);

      return formErrorKeys.some((key) => formValuesKeys.includes(key));
    });

    if (isErrorValid) {
      return;
    }

    if (formDetail?.form_type === FormType.Request) {
      setCurrentIndex((prev) => prev + 1);
    } else if (formDetail?.form_type === FormType.Survey) {
      try {
        const length = form.watch('forms').length;

        const array = Array.from({ length }, (_, i) => i);

        const filledForm = form.watch('forms');
        const data = {
          sections: array.map((arr, index) => {
            const savedSection = formDetail.sections[index];
            const section = filledForm[index];
            if (!section) {
              return {
                section_id: savedSection.id,
                fields: [],
              };
            }

            const keys = Object.keys(section);
            const fieldLabel = savedSection.fields.map((field) => field.label);

            // sort the keys based on the field label
            const sortedKeys = keys.sort((a, b) => {
              return fieldLabel.indexOf(a) - fieldLabel.indexOf(b);
            });

            const fields = sortedKeys.reduce((acc, key, idx) => {
              const fieldId = savedSection.fields[idx].id;
              acc[fieldId] = section[key];
              return acc;
            }, {});

            return {
              section_id: savedSection.id,
              fields,
            };
          }),
        };

        const result = !isEdit
          ? await dispatch(
              createFormResponseById({
                ...data,
                formId: id,
                email: form.watch('email'),
                respondent_email: form.watch('email'),
              }),
            )
          : await dispatch(
              updateFormResponseById({
                ...data,
                formId: id,
                formResponseId: responseId,
                email: form.watch('email'),
                respondent_email: form.watch('email'),
              }),
            );
        unwrapResult(result);
        setCurrentIndex((prev) => prev + 1);
      } catch (error) {
        toast.error('Failed to submit form');
      }
    }
  };

  const combinedDateTime = (isoDate, newTime) => {
    if (!isoDate || !newTime) return;
    // Parse the ISO string into a Date object
    const parsedDate = parseISO(new Date(isoDate).toISOString());
    // Extract hours, minutes, and seconds from the new time
    const [hours, minutes] = newTime.split(':').map(Number);

    // Set the new time on the parsed date
    const updatedDate = setSeconds(setMinutes(setHours(parsedDate, hours), minutes), '00');

    return updatedDate;
  };

  const handleSignature = async () => {
    const isValid = await form.trigger('signature');
    if (isValid) {
      try {
        const length = form.watch('forms')?.length;

        const array = Array.from({ length }, (_, i) => i);

        const filledForm = form.watch('forms');

        const data = {
          sections: array.map((arr, index) => {
            const savedSection = formDetail.sections[index];
            const section = filledForm[index];

            if (!section || (section && Object.keys(section)?.length === 0)) {
              return {
                section_id: savedSection.id,
                fields: [],
              };
            }

            const keys = Object.keys(section);
            const fieldLabel = savedSection.fields.map((field) => field.label);

            // sort the keys based on the field label
            const sortedKeys = keys.sort((a, b) => {
              return fieldLabel.indexOf(a) - fieldLabel.indexOf(b);
            });

            const fields = sortedKeys.reduce((acc, key, idx) => {
              const fieldId = savedSection.fields[idx].id;
              acc[fieldId] = section[key];
              return acc;
            }, {});

            return {
              section_id: savedSection.id,
              fields,
            };
          }),
        };

        const startTime = combinedDateTime(form.watch('start_date'), form.watch('start_time'));
        const endTime = combinedDateTime(form.watch('end_date'), form.watch('end_time'));

        const result = !isEdit
          ? await dispatch(
              createFormResponseById({
                ...data,
                formId: id,
                start_time: startTime,
                end_time: endTime,
                requester_signature: form.watch('signature'),
              }),
            )
          : await dispatch(
              updateFormResponseById({
                ...data,
                formId: id,
                formResponseId: responseId,
                start_time: startTime,
                end_time: endTime,
                requester_signature: form.watch('signature'),
              }),
            );
        unwrapResult(result);
        setCurrentIndex((prev) => prev + 1);
      } catch (error) {
        toast.error('Failed to submit form');
      }
    }
  };

  const handleSurveyPasswordSubmit = async () => {
    const isValid = await form.trigger('password');

    if (isValid) {
      try {
        const result = await dispatch(
          validateForm({
            form_password: form.watch('password'),
            id,
          }),
        );
        unwrapResult(result);
        setCurrentIndex((prev) => prev + 1);
      } catch (error) {
        form.setError('password', {
          type: 'manual',
          message: 'Wrong Password! Ask administration to give you password.',
        });
      }
    }
  };

  const handleSurveyEmailSubmit = async () => {
    const isValid = await form.trigger('email');

    if (isValid) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const handleSetDueDate = async () => {
    const isValid = await form.trigger(['start_date', 'start_time', 'end_date', 'end_time']);

    if (isValid) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const onNext = () => {
    if (formDetail?.form_type === FormType.Request) {
      if (currentIndex === 0) {
        handleSecureAccess();
      } else if (currentIndex === 1) {
        handleFinishFillForm();
      } else if (currentIndex === 2) {
        if (isDueDate) {
          handleSetDueDate();
        } else {
          handleSignature();
        }
      } else if (currentIndex === 3) {
        handleSignature();
      }
    } else if (formDetail?.form_type === FormType.Survey) {
      if (currentIndex === 0) {
        handleSurveyPasswordSubmit();
      } else if (currentIndex === 1) {
        handleSurveyEmailSubmit();
      } else if (currentIndex === 2) {
        handleFinishFillForm();
      }
    }
  };

  const onBack = () => {
    setCurrentIndex((prev) => prev - 1);
  };

  const showBackButton = () => {
    if (formDetail?.form_type === FormType.Request) {
      if (isDueDate) {
        return currentIndex !== 0 && currentIndex !== 1 && currentIndex !== 4;
      } else {
        return currentIndex !== 0 && currentIndex !== 1 && currentIndex !== 3;
      }
    } else if (formDetail?.form_type === FormType.Survey) {
      return currentIndex !== 0 && currentIndex !== 2 && currentIndex !== 3;
    }
  };

  const showNextButton = () => {
    if (formDetail?.form_type === FormType.Request) {
      if (isDueDate) {
        return currentIndex !== 3 && currentIndex !== 1 && currentIndex !== 4;
      } else {
        return currentIndex !== 3 && currentIndex !== 1 && currentIndex !== 2;
      }
    } else if (formDetail?.form_type === FormType.Survey) {
      return currentIndex === 0 || currentIndex === 1;
    }
  };

  const showSubmitButton = () => {
    if (formDetail?.form_type === FormType.Request) {
      if (isDueDate) {
        return currentIndex === 3;
      } else {
        return currentIndex === 2;
      }
    } else if (formDetail?.form_type === FormType.Survey) {
      return false;
    }
  };

  if (
    formDetail?.status === 'closed' ||
    (formResponseDetail?.response_status !== 'rejected' && isEdit)
  ) {
    return <NotFound />;
  }

  return (
    <Form {...form}>
      <div className="my-[73px] bg-lightGray max-w-[848px] mx-auto p-6 h-full shadow-md rounded-[24px] space-y-6">
        {currentIndex === 0 && (
          <div>{isSurveyForm ? <FormSurveySecureAccess /> : <FormRequestSecureAccess />}</div>
        )}

        {currentIndex === 1 && (
          <div>
            {isSurveyForm ? (
              <FormSurveySecureAccessPart2 />
            ) : (
              <FormSubmitForm onNext={onNext} onBack={onBack} />
            )}
          </div>
        )}

        {currentIndex === 2 && (
          <div>
            {isSurveyForm ? (
              <FormSubmitForm onNext={onNext} onBack={onBack} />
            ) : isDueDate ? (
              <FormSetDueDate isEdit={isEdit} />
            ) : (
              <FormRequestSignature />
            )}
          </div>
        )}

        {currentIndex === 3 && (
          <div>
            {isSurveyForm ? (
              <FormSurveyEnding />
            ) : isDueDate ? (
              <FormRequestSignature />
            ) : (
              <FormRequestEnding />
            )}
          </div>
        )}

        {currentIndex === 4 && formDetail?.form_type === FormType.Request && isDueDate && (
          <FormRequestEnding />
        )}

        {/* Navigatio Button */}
        <div className="flex items-center gap-4 mt-6">
          {showBackButton() && (
            <Button onClick={onBack} variant="outline" className="w-[56px] h-[42px] ">
              Back
            </Button>
          )}
          {showNextButton() && (
            <Button onClick={onNext} variant="outline" className="w-[56px] h-[42px]">
              Next
            </Button>
          )}

          {showSubmitButton() && (
            <Button onClick={onNext} className="w-[72px] h-[42px] self-end ml-auto">
              Submit
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
}
