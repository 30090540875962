import React from 'react';
import { useSelector } from 'react-redux';

import { Badge } from '../../../../components/badge/badge';
import Tabs from '../../../../components/tabs/tabs';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { FormResponseStatus } from '../../config/forms/constant';

export default function FormDetailResponsesTab({ setSelectedTab, selectedTab }) {
  const { formResponseDetail } = useSelector((state) => state.form);

  const getStatusVariant = (value) => {
    switch (value) {
      case FormResponseStatus.NewRequest.toLowerCase():
        return 'default';
      case FormResponseStatus.AwaitingApproval.toLowerCase():
        return 'warning';
      case FormResponseStatus.Approved.toLowerCase():
        return 'success';
      case FormResponseStatus.Cancelled.toLowerCase():
      case 'canceled':
        return 'danger';
      case FormResponseStatus.Closed.toLowerCase():
        return 'secondary';
      default:
        return 'default';
    }
  };

  return (
    <div className="relative">
      <Tabs
        tabList={[
          {
            label: 'Form Details',
            value: 'formDetails',
            onClick: (tab) => {
              setSelectedTab(tab);
            },
          },
          {
            label: 'Approver',
            value: 'approver',
            onClick: (tab) => {
              setSelectedTab(tab);
            },
          },
          {
            label: 'Approval',
            value: 'approval',
            disabled: formResponseDetail?.approvers?.length === 0,
            onClick: (tab) => {
              setSelectedTab(tab);
            },
          },
          {
            label: 'Activities',
            value: 'activities',
            onClick: (tab) => {
              setSelectedTab(tab);
            },
          },
        ]}
        selectedTab={selectedTab}
        showTooltip={false}
      />

      <div className="flex items-center gap-2 absolute right-0 top-0">
        <p className="text-sm text-neutral-color-main mb-0">Status</p>

        <Badge variant={getStatusVariant(formResponseDetail?.response_status)}>
          {capitalizeFirstLetter(formResponseDetail?.response_status)}
        </Badge>
      </div>
    </div>
  );
}
