/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import { resetPassword, resetAuth, checkToken } from '../../redux/features/auth/authSlice';
// import foxLogo from '../../assets/img/FOX_Logo2.png.webp';
import tokenEx from "../../assets/img/tokenEx.jpg"
import '../resetPasswordByEmail/resetPasswordByEmail.scss';
import { ORIGIN_FRONT_URL } from '../../utils/axios';

export default function UserResetNewPassword () {
  const history = useHistory();
  const { uidb, token } = useParams();
  const { isLoading, isError, isSuccess , isCheckTokenError , isCheckTokenLoading } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [newPassword, setnewPassword] = useState('');
  const [firstname, setfirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [isChLoading, setIsChLoading] = useState(true);

  const submitConfirmPass = e => {
    e.preventDefault();
    const info = { new_password: newPassword, first_name: firstname, last_name: lastname };
    const userInfo = {
      uidb64: uidb,
      token: token,
      info,
    };
    dispatch(resetPassword(userInfo));
  };
  const { showSpinner } = useApiCallStatusNotificationHandler({
    isSuccess,
    isLoading,
    isError,
    successCallBack: () =>
      setTimeout(() => {
        window.location.assign(ORIGIN_FRONT_URL)
        // history.push('/login');
      }, 3500),
  });

  const userInfo = {
    uidb64: uidb,
    token: token,
  };
  useEffect(() => {
    const check = async () => {
      if (localStorage.getItem('userToken')) {
        localStorage.removeItem('userToken');
      }
      await dispatch(checkToken(userInfo));
      setIsChLoading(false)
    }
    check()
  }, [])


  useEffect(() => {
    dispatch(resetAuth());
  }, [isError, isSuccess, isLoading, dispatch, history]);
  return (
    isCheckTokenError ? <div className='token-wrapper'>
    <img src={tokenEx} alt="tokenEx" className='token-image' />
    <p className='token-content'>Oops! This link has expired.</p>
    <p className='token-content'>For further assistance, contact the system administrator.</p>
    <button onClick={()=> {
      history.push('/login');
    }} className='btn primary-btn mt-3' type='button'>Redirect to login page</button>
    </div>: 
    <div>
      {isChLoading ? <div id="loader" /> : 
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-lg-7 col-sm-12 col-md-10 ">
          {/* <div className="login-logo-check-email">
            <img src={foxLogo} alt="fox logo" height="130" width="250" />
          </div> */}
          {/* <p className="mb-2 text-success">{{msg}}</p> */}
          <form action="{{action}}" method="POST" onSubmit={submitConfirmPass}>
            <div className="card col-lg-12 col-sm-12 col-xs-12 col-md-12 reset-pass-card">
              <div className="card-header medium-title" style={{ borderBottom: 'none' }}>
                <b>Create new password</b>
              </div>
              <div className="card-body">
                <div className="row">
                  <span> </span>
                  <label className="font-weight-normal" htmlFor="newpassword">
                    New Password{' '}
                    <label className="font-danger m-0" htmlFor="newpassword">
                      *
                    </label>
                  </label>
                  <input
                    name="newpassword"
                    type="password"
                    className="form-control mb-2"
                    placeholder="New password"
                    required
                    id="newpassword"
                    onChange={e => setnewPassword(e.target.value)}
                  />
                  <span className="font-danger col-lg-12 col-sm-12 ml-0" id="newpassword_error">
                    {' '}
                  </span>

                  <label className="font-weight-normal" htmlFor="confirm_password">
                    First Name{' '}
                    <label className="font-danger m-0" htmlFor="firstName">
                      *
                    </label>
                  </label>
                  <input
                    name="firstName"
                    type="text"
                    className="form-control mb-2"
                    required
                    placeholder="First Name"
                    id="firstName"
                    onChange={e => setfirstName(e.target.value)}
                  />
                  <span
                    className="font-danger col-lg-12 col-sm-12 ml-0"
                    id="confirm_password_error"
                  >
                    {' '}
                  </span>

                  <label className="font-weight-normal" htmlFor="otp">
                    Last Name{' '}
                    <label className="font-danger m-0" htmlFor="lastname">
                      *
                    </label>
                  </label>
                  <input
                    name="last name"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Last Name"
                    id="lastname"
                    onChange={e => setLastName(e.target.value)}
                  />
                  <span className="font-danger col-lg-12 col-sm-12 ml-0" id="otp_error">
                    {' '}
                  </span>
                </div>
              </div>
            </div>

            <div className="float-right">
              <div className="float-right">
                <button className="btn primary-btn" type="submit">
                  Save
                </button>
                {/* <button  className="btn secondary-btn ml-2" type="button">Back</button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      }
      <div className="text-center my-3" style={{ color: '#9B9B9B !important' }}>
        {' '}
        © 2022 Infinity Wave Sdn. Bhd.
      </div>
      <ReactTooltip
        anchorSelect="#newpassword"
        place="top"
        content={
          <div style={{ width: '250px' }}>
            <p>{`Your password cant't be too similar to your other personal information`}</p>
            <p>Password must contain at least 8 characters</p>
            <p>{`Your password cant't be a commonly used password`}</p>
            <p>{`Your password cant't be entirely numeric`}</p>
          </div>
        }
      />
    </div>
  );
}
