import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cropper from 'react-cropper';
import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import {
  reset,
  getUserProfile,
  updateUserProfileImg,
  updateUserProfile,
} from '../../redux/features/user/userSlice';
import { getDepartmentsByAuthentication } from '../../redux/features/department/departmentSlice';
import profilePicture from '../../assets/img/profile.png';
import './profile.scss';
import { getManagerPositionByAuthentication } from '../../redux/features/managerPosition/managerPositionSlice';
import { getTechnicianPositionsByAuthentication } from '../../redux/features/technicianPosition/technicianPositionSlice';

export default function Profile() {
  const history = useHistory();
  const { departments } = useSelector((state) => state.department);
  const { managers } = useSelector((state) => state.managerPosition);
  // eslint-disable-next-line no-unused-vars
  const { technicians } = useSelector((state) => state.technicianPosition);
  const dispatch = useDispatch();
  const { user, isLoading, isError, isSuccess } = useSelector((state) => state.user);
  const company = useSelector((state) => state.company.profile);
  // const [image] = useState('');
  const [image, setImage] = useState('');
  const statuses = [true, false]
  const [cropData, setCropData] = useState('#');

  const [crop, setCrop] = useState(false);
  const cropperRef = useRef(null);
  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    profile: {
      image: '',
      phone_number: '',
      department: '',
      job_title: '',
    },
  });
  const [firstName, setFirstName] = useState(profile.first_name);
  const [lastname, setLastName] = useState(profile.last_name);
  const [phone, setPhone] = useState(profile.profile.phone_number);
  const [job, setJob] = useState(profile.profile.job_title);
  const [dep, setDep] = useState(profile.profile.department);
  const [status, setStatus] = useState(profile.status);
  // const [lowInv, setLowInv] = useState(user?.profile?.inv_low_email);
  // const [setPosIndex] = useState();
  // const [posIndex, setPosIndex] = useState();
  // eslint-disable-next-line no-unused-vars
  const [pos, setPos] = useState(0);
  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getDepartmentsByAuthentication());
    dispatch(getManagerPositionByAuthentication());
    dispatch(getTechnicianPositionsByAuthentication());
  }, []);

  useEffect(() => {
    const imageString = user?.profile.image?.image
      ? user?.profile.image?.image
      : user?.profile.image;
    setProfile({
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      role: user?.role,
      profile: {
        image: imageString,
        phone_number: user?.profile.phone_number,
        department: user?.profile.department,
        job_title: user?.profile.job_title,
        // inv_low_email: user?.profile?.inv_low_email,
      },
      status: user?.status
    });
    setFirstName(user?.first_name);
    setLastName(user?.last_name);
    setPhone(user?.profile.phone_number);
    setJob(user?.profile.job_title);
    setDep(user?.profile.department);
    setStatus(user?.status);
    // setLowInv(user?.profile?.inv_low_email);

    // set the position index of the manager and technician

    if (profile?.role === 'Manager') {
      setPos(user?.manager?.position);
      // setPosIndex(user?.manager?.position);
    } else if (profile?.role === 'Technical Team') {
      // setPosIndex(user?.technicalteam?.position);
      setPos(user?.technicalteam?.position)
    }
  }, [user]);
  

  const cropImage = (e) => {
    setCrop(true);
    const imgurl = URL.createObjectURL(e.target.files[0]);
    setImage(imgurl);
    e.preventDefault();
  };

  const getCropData = (e) => {
    e.preventDefault();
    if (cropperRef.current?.cropper !== 'undefined') {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();

        // Pass the image file name as the third parameter if necessary.
        formData.append('image', blob, 'example.png');

        // Use `jQuery.ajax` method for example
        dispatch(updateUserProfileImg(formData));
      }, 'image/png');
      setCrop(false);
    }
  };
  useEffect(() => {
    setProfile({ ...profile, profile: { image: cropData } });
  }, [cropData]);

  const handleUpdateProfile = (e) => {
    const department = parseInt(dep, 10) || null
    const position = parseInt(pos, 10) || null
    e.preventDefault();
    const userinfo = {
      first_name: firstName,
      last_name: lastname,
      profile: {
        phone_number: phone,
        job_title: job,
        department,
        // inv_low_email: lowInv,
      },
      status
    };
    if (profile.role === 'Manager') {
      userinfo.manager = {
        position,
        hourly_rate: user.manager.hourly_rate,
      };
    } else if (profile.role === 'Technical Team') {
      userinfo.technicalteam = {
        position: parseInt(pos, 10),
        hourly_rate: user.technicalteam.hourly_rate || '0.00',
        tech_manager: user?.technicalteam?.tech_manager,
      };
    }
    dispatch(updateUserProfile(userinfo));
  };

  useEffect(() => {
    dispatch(reset());
  }, [user, isError, isSuccess, isLoading, dispatch, history]);

  const { showSpinner } = useApiCallStatusNotificationHandler({
    isSuccess,
    isLoading,
    isError,
    successCallBack: () =>
      setTimeout(() => {
        history.push('/profile/');
      }, 3500),
  });

  const handlePosition = (e) => {
    setPos(e.target.value);
    if (profile.role === 'Manager') {
      managers.forEach((manager) => {
        if (parseInt(e.target.value, 10) === manager.id) {
          setJob(manager.name);
        }
      });
    } else {
      technicians.forEach((technician) => {
        if (parseInt(e.target.value, 10) === technician.id) {
          setJob(technician.name);
        }
      });
    }
  };
  return (
    <>
      {!crop && (
        <form
          action="{{action}}"
          method="POST"
          // encType="multipart/form-data"
          onSubmit={handleUpdateProfile}
        >
          {showSpinner ? <div id="loader" /> : null}

          <div className="card-header h-20 row justify-content-around col-lg-12">
            <div className="row justify-content-start my-auto col-lg-9 col-md-8 col-sm-12">
              <div className="icon img-hover ml-4 my-auto ">
                <div className="icon img-select ">
                  <input
                    type="file"
                    id="profile_image_user"
                    name="profile_image"
                    accept="image/*"
                    className="none"
                  />
                </div>
                <label htmlFor="crop_image" style={{ cursor: 'pointer' }} className="image-label">
                  {profile.profile.image ? (
                    <img
                      className="prof_img img-circle user_profile "
                      height="120px"
                      width="120px"
                      alt="profile"
                      src={profile.profile.image}
                      id="result_profile"
                      title="Change profile picture"
                    />
                  ) : (
                    <img
                      className="prof_img img-circle user_profile "
                      height="120"
                      width="120"
                      alt="profile"
                      src={profilePicture}
                      id="result_profile"
                      title="Change profile picture"
                    />
                  )}
                  <div className="edit-icon">
                    <div style={{ fontSize: '18px' }} className="fas fa-pen"> </div>
                  </div>
                </label>

                <br />
                <span id="image_name" className="small-font gcolor" />
              </div>
              <div className="row pl-4 col-8 my-auto">
                <div className="row col-lg-12 ml-1">
                  {' '}
                  <h4>{`${firstName} ${lastname}`}</h4>{' '}
                </div>
                <div className="row col-lg-12">
                  <a className=" ml-2" href="/change-password">
                    Change Password
                  </a>
                  <div className="mx-3" />
                  {/* <label htmlFor="crop_image" style={{ cursor: 'pointer' }}>
                    Change Profile Photo
                  </label> */}
                  <input
                    value=""
                    name="crop"
                    id="crop_image"
                    onChange={cropImage}
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
            <div className="float-right my-auto col-lg-3 col-md-4 col-sm-12 d-flex justify-content-end">
              <button
                className="btn primary-btn"
                type="submit"
                id="profile_update"
                onClick={() => {
                  if (this instanceof Element) {
                    // eslint-disable-next-line react/no-this-in-sfc
                    this.classList.toggle('button--loading');
                  }
                }}
              >
                <span className="button__text">Update</span>
              </button>
              <button
                className="btn secondary-btn ml-2"
                type="button"
                onClick={() => history.push(company?.subscription?.can_view_ebq && user?.can_view_ebq ? "/ebq" : '/')}
              >
                Back
              </button>
            </div>
          </div>
          {/* page alignment */}
          <div className="card-body mx-auto row justify-content-center col-lg-12">
            <div className="row col-lg-8 col-sm-12">
              <div className="card col-lg-12 col-sm-12 custom_profile_card">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label htmlFor="firstName" className="font-weight-normal">
                        First Name
                      </label>
                      <input
                        name="firstName"
                        type="text"
                        className="form-control mb-2"
                        // placeholder="First Name"
                        id="firstName"
                        placeholder={profile.first_name}
                        defaultValue={profile.first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      &nbsp;
                    </div>

                    <div className="col-lg-6  col-md-6 col-sm-12">
                      <label htmlFor="lastName" className="font-weight-normal">
                        Last Name
                      </label>
                      <input
                        name="lastName"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Last Name"
                        id="lastName"
                        defaultValue={profile.last_name}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      &nbsp;
                    </div>

                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <label htmlFor="email" className="font-weight-normal ">
                        Email
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="form-control mb-2"
                        placeholder="Email"
                        id="email"
                        onChange="remove_error_msg('email')"
                        value={profile.email}
                        readOnly
                        disabled
                      />
                      &nbsp;
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="email_error" />
                      </div>
                    </div>
                    {profile.role === 'Manager' ||
                      profile.role === 'Technical Team' ||
                      profile.role === 'Requestor' ? (
                      <div className="col-lg-6 col-md-4 col-sm-12">
                        <label htmlFor="phoneNo" className="font-weight-normal">
                          Phone Number
                        </label>
                        <input
                          name="phoneNo"
                          type="text"
                          className="form-control mb-2"
                          placeholder="e.g. +601199119911"
                          id="phoneNo"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    ) : (
                      <div className="col-lg-6  col-md-4 col-sm-12">
                        <label htmlFor="phoneNo" className="font-weight-normal">
                          Phone Number
                        </label>
                        <input
                          name="phoneNo"
                          type="text"
                          className="form-control mb-2"
                          placeholder="e.g. +601199119911"
                          id="phoneNo"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    )}

                    <div className="col-lg-6 col-sm-12">
                      <label htmlFor="role" className="font-weight-normal">
                        Account Types
                      </label>
                      {profile?.role === 'CliAdm' ? (
                        <input
                          name="role"
                          type="text"
                          className="form-control mb-2"
                          value="Administrator"
                          readOnly
                          disabled
                        />
                      ) : (
                        <input
                          name="role"
                          type="text"
                          className="form-control mb-2"
                          value={profile?.role}
                          readOnly
                          disabled
                        />
                      )}
                    </div>

                    {profile.role === 'Manager' || profile.role === 'Technical Team' ? (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="department" className="font-weight-normal">
                          Department &nbsp;
                        </label>

                        <select
                          disabled={profile.role !== "CliAdm"}
                          className=" mb-2"
                          name="department"
                          id="department"
                          onChange={(e) => setDep(+e.target.value)}
                        >
                          <option value="" disabled selected>
                            --SELECT--
                          </option>
                          {departments?.map((department) =>
                            profile?.profile?.department === department.id ? (
                              <option value={department.id} selected>
                                {department.name}
                              </option>
                            ) : (
                              <option value={department.id}>{department?.name}</option>
                            ),
                          )}
                        </select>
                        <div className="col-lg-12 col-sm-12 m-0">
                          <span className="font-danger" id="dept_error" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label htmlFor="position" className="font-weight-normal">
                            Position &nbsp;
                          </label>

                          <select
                            className=" mb-2"
                            name="position"
                            id="position"
                            onChange={handlePosition}
                          >
                            <option value="" disabled selected>
                              --SELECT--
                            </option>
                            {/* position for Manager */}
                            {profile?.role === 'Manager' &&
                              managers.map((manager) =>
                                parseInt(user.manager?.position, 10) === manager.id ? (
                                  <option value={manager.id} selected>
                                    {manager?.name}
                                  </option>
                                ) : (
                                  <option value={manager.id}>{manager.name}</option>
                                ),
                              )}
                            {/* position for technician team */}

                            {profile?.role === 'Technical Team' &&
                              technicians.map((technician) =>
                                parseInt(user.technician?.position, 10) === technician.id ? (
                                  <option value={technician.id} selected>
                                    {technician?.name}
                                  </option>
                                ) : (
                                  <option value={technician.id}>{technician.name}</option>
                                ),
                              )}
                          </select>
                        </div>
                        <div className="col-lg-12 col-sm-12 m-0">
                          <span className="font-danger" id="position_error" />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="col-lg-6 col-sm-12">
                          <label htmlFor="jobTitle" className="font-weight-normal">
                            Job Title
                          </label>
                          <input
                            name="jobTitle"
                            type="text"
                            className="form-control mb-2"
                            value={job}
                            placeholder="Job Title"
                            onChange={(e) => setJob(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <label htmlFor="department" className="font-weight-normal">
                            Department
                          </label>

                          <select
                            className=" mb-2"
                            name="department"
                            id="department"
                            onChange={(e) => setDep(e.target.value)}
                          >
                            <option value="" disabled selected>
                              --SELECT--
                            </option>

                            {departments?.map((department) =>
                              profile?.profile?.department === department.id ? (
                                <option value={department.id} selected>
                                  {department?.name}
                                </option>
                              ) : (
                                <option value={department.id}>{department?.name}</option>
                              ),
                            )}
                          </select>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <label htmlFor="department" className="font-weight-normal">
                            Status
                          </label>
                          <select
                            className="mb-2"
                            name="status"
                            id="status"
                            onChange={(e) => setStatus(e.target.value)}
                          >

                            <option value="" disabled defaultValue>
                              --SELECT--
                            </option>

                            {statuses?.map((stat) => (
                              <option key={stat} value={stat} selected={profile?.status === stat}>
                                {stat ? 'Available' : 'Unavailable'}
                              </option>
                            ))}
                          </select>

                        </div>
                      </>
                    )}

                    {/* {profile?.role !== 'Requestor' ? (
                      <div className="col-lg-12 col-sm-12 p-2">
                        <div className="pb-2">
                          <b>Email Notification Setting</b>
                        </div>
                        <br />
                        <div className="row">
                          <input
                            checked={lowInv}
                            onChange={(e) => setLowInv(e.target.checked)}
                            type="checkbox"
                            className="switch mt-2"
                            id="low_inv_email"
                          />
                          <div className="font-weight-normal pl-4">Low inventory unit</div>
                        </div>
                      </div>
                    ) : (
                      <input type="hidden" id="inv_low" name="inv_low" value="False" />
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {crop ? (
        <div className="col croper">
          <Cropper
            style={{ height: '100%', width: '100%' }}
            initialAspectRatio={1}
            className="row align-items-center my-auto col-lg-12 col-md-12 col-sm-12 croper"
            preview=".img-preview"
            src={image}
            ref={cropperRef}
            viewMode={0.5}
            guides
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background
            responsive
            checkOrientation={false}
          />
          <div
            className="box"
          // style={{ width: "50%", float: "right", height: "300px" }}
          >
            <div className="buttons_crop">
              <button onClick={() => setCrop(false)} type="button" className="btn btn-danger">
                Cancel
              </button>

              <button onClick={getCropData} type="button" className="btn btn-success">
                Crop Image
              </button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
