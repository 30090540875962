/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Popover, PopoverContent, PopoverTrigger } from '../popover/popover';
import Button from '../button/button';
import { cn } from '../../utils/utilClassNames';
import { Calendar } from '../calendar/calendarV2';
import CalendarIcon from '../../assets/svg-icons/CalendarIcon';

export default function DatepickerInput({
  onChange,
  value,
  disabled,
  showPrefix,
  showSuffix,
  className,
  suffixClassName,
  placeholder = 'Pick a date',
  isPortal,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const [date, setDate] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState(value);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  return (
    <Popover open={disabled ? false : isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          onClick={() => {
            if (!disabled) setIsOpen(!isOpen);
          }}
          variant="outline"
          className={cn(
            'w-full justify-start text-left font-normal h-[42px] text-sm !bg-neutral-color-theme-color-light',
            disabled ? '!cursor-not-allowed' : 'cursor-pointer',
            className,
          )}
        >
          {showPrefix && <CalendarIcon />}

          {selectedDate ? (
            <span className="flex-1">{format(selectedDate, 'dd MMM yyyy')}</span>
          ) : (
            <span className="text-neutral-color-neutral-variant-color-main flex-1">
              {placeholder}
            </span>
          )}

          {showSuffix && (
            <CalendarIcon className={cn('text-theme-color-secondary-main', suffixClassName)} />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent open={isOpen} isPortal={isPortal} className="w-[376px] p-0" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
          onClickSet={(date) => {
            setSelectedDate(date);
            onChange(date);
            setIsOpen(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
}
