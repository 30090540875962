import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import FormPreviewHeader from '../FormPreview/FormPreviewHeader';
import FormSubmitDetailForm from './FormSubmitDetailForm';
import Button from '../../../../components/button/button';
import { FormType } from '../../config/forms/constant';

export default function FormSubmitForm({ onNext: onParentNext, onBack: onParentBack }) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const { form: formDetail } = useSelector((state) => state.form);

  const isSurvey = formDetail?.form_type === FormType.Survey;

  const formSections = useMemo(() => {
    return formDetail?.sections?.map((section) => {
      return {
        id: Math.random(),
        title: section.title,
        description: section.description,
        items: section.fields?.map((item) => {
          return {
            id: Math.random(),
            question: item.label,
            answer: item.field_type,
            content: item.options
              ? JSON.parse(item.options).map((option) => {
                  return {
                    id: option.id,
                    text: option.text,
                    type: option.type || undefined,
                  };
                })
              : undefined,
            isRequired: item.is_required,
          };
        }),
      };
    });
  }, [formDetail]);

  const isLast = (formSections?.length || 1) - 1;

  const onBack = () => {
    if (currentIndex === 0) {
      onParentBack();

      return;
    }
    setCurrentIndex((prev) => prev - 1);
  };

  const onNext = () => {
    if (currentIndex === isLast) {
      onParentNext();
      return;
    }
    setCurrentIndex((prev) => prev + 1);
  };

  const hideNextButton = isSurvey && currentIndex === isLast;
  const showSubmitButton = isSurvey && currentIndex === isLast;

  return (
    <div className="pb-2">
      <FormPreviewHeader />

      <FormSubmitDetailForm currentIndex={currentIndex} data={formSections} />

      <div className="flex items-center gap-4 mt-6">
        <Button onClick={onBack} variant="outline" className="w-[56px] h-[42px] ">
          Back
        </Button>

        {!hideNextButton && (
          <Button onClick={onNext} variant="outline" className="w-[56px] h-[42px]">
            Next
          </Button>
        )}

        {showSubmitButton && (
          <Button onClick={onNext} className="w-[72px] h-[42px] self-end ml-auto">
            Submit
          </Button>
        )}
      </div>
    </div>
  );
}
