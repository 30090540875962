/* eslint-disable no-else-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable new-cap */
import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';

import StarIcon from '../../../../assets/svg-icons/StarIcon';
import Table from '../../../../components/crudTable/crudTable';
import TextArea from '../../../../components/input/textArea';
import TextInput from '../../../../components/input/textInput';
import Label from '../../../../components/label/label';
import { cn } from '../../../../utils/utilClassNames';
import { FieldType } from '../../config/forms/constant';

export default function FormResponseDetailSurveyPreview() {
  const { formResponseDetail, form } = useSelector((state) => state.form);

  const sections = formResponseDetail?.section_responses;

  return (
    <div className="max-w-screen-2xl mx-auto">
      <form className="flex flex-col gap-4 my-4 bg-white px-4 pb-4" noValidate>
        <div>
          <h2 className="text-neutral-color-darker text-2xl font-semibold mb-0">
            {formResponseDetail?.form_response_number || ''}
          </h2>
        </div>

        <div className="border-b border-gray pb-4">
          <h2 className="text-neutral-color-darker font-semibold text-base mb-2">
            Respondent information
          </h2>

          <div>
            <Label className="mb-2">Email</Label>
            <TextInput disabled value={formResponseDetail?.respondent_email} />
          </div>
        </div>

        <div>
          <h2 className="text-neutral-color-darker font-semibold text-base mb-2">Form details</h2>

          <div className="border border-neutral-color-light rounded-lg p-4">
            <div className="pb-2 border-b border-neutral-color-divider">
              <h3 className="font-semibold text-lg text-neutral-color-darker mb-0">
                {form?.title || ''}
              </h3>
              <p className="text-sm text-neutral-color-main mb-0">{form?.description || ''}</p>
            </div>

            {sections?.map((section, sectionIdx) => {
              return (
                <div className="mt-2 space-y-2" key={sectionIdx}>
                  <h4 className="font-semibold text-sm text-neutral-color-darker">
                    {section.title}
                  </h4>

                  {section?.field_responses?.map((item, fieldIdx) => {
                    return (
                      <div key={fieldIdx}>
                        <Label className="mb-2">{item.field_label}</Label>
                        {/* TODO image, upload image */}
                        {(() => {
                          if (
                            item.field_type === FieldType.ShortText ||
                            item.field_type === FieldType.Checkboxes ||
                            item.field_type === FieldType.Dropdown ||
                            item.field_type === FieldType.MultipleChoices
                          ) {
                            return <TextInput value={item.value} disabled />;
                          } else if (item.field_type === FieldType.LongText) {
                            return <TextArea disabled className="!h-[126px]" value={item.value} />;
                          } else if (item.field_type === FieldType.Image) {
                            return (
                              <div
                                className={cn(
                                  'rounded-[10px] border-[0.83px] border-dashed border-gray flex items-center justify-center size-[130px]',
                                )}
                              >
                                <div className="relative w-full h-full">
                                  <img
                                    src={item.value}
                                    alt="submit answer"
                                    className="h-full w-full object-contain rounded"
                                  />
                                </div>
                              </div>
                            );
                          } else if (item.field_type === FieldType.FileUpload) {
                            return (
                              <div
                                className={cn(
                                  'rounded-[10px] border-[0.83px] border-dashed border-gray flex items-center justify-center size-[130px]',
                                )}
                              >
                                <div className="relative w-full h-full flex items-center justify-center flex-col">
                                  <span role="img" aria-label="document">
                                    📄
                                  </span>
                                  <p className="mb-0 text-sm">{item.content}</p>
                                </div>
                              </div>
                            );
                          } else if (item.field_type === FieldType.Date) {
                            const dateValue = item.value
                              ? format(new Date(item.value), 'MMM, d yyyy')
                              : '-';
                            return <TextInput value={dateValue} disabled />;
                          } else if (item.field_type === FieldType.Time) {
                            const date = new Date();
                            const time = item?.value?.split(':');
                            date.setHours(time[0], time[1], 0);

                            // Format the date to include AM/PM
                            const formattedTime = format(date, 'hh:mm a');

                            return <TextInput value={formattedTime} disabled />;
                          } else if (item.field_type === FieldType.Rating) {
                            const fieldId = item.field;

                            const allFields = form?.sections
                              ?.map((sectionItem) => sectionItem.fields)
                              .flat(Infinity);

                            const selectedFields = allFields?.find((field) => field.id === fieldId);

                            const option = selectedFields?.options
                              ? JSON.parse(selectedFields.options)[0].text
                              : [];

                            const arrayOptions = Array.from({ length: option }, (_, i) => i + 1);

                            return (
                              <div className="flex items-start gap-6 text-center text-sm h-fit md:w-1/2 justify-center">
                                {arrayOptions.map((optionItem, idx) => {
                                  return (
                                    <div key={idx}>
                                      <StarIcon
                                        className={cn(
                                          'cursor-pointer',
                                          idx < item.value
                                            ? 'text-generic-color-alpha-orange-92 fill-generic-color-alpha-orange-92'
                                            : '',
                                        )}
                                      />
                                      <p className="mb-0">{optionItem}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          } else if (item.field_type === FieldType.Table) {
                            const columns = item.content || [];

                            const tableColumns = columns?.map((column) => {
                              return {
                                Header: column.text,
                                accessor: column.text,
                              };
                            });
                            return <Table columns={tableColumns} data={item.data} />;
                          } else {
                            return null;
                          }
                        })()}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </div>
  );
}
