/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable new-cap */
import React from 'react';
import FormResponseDetailFormDetailTabContent from '../FormResponseDetail/FormResponseDetailFormDetailTabContent';
import FormResponseDetailHeader from '../FormResponseDetail/FormResponseDetailHeader';

export default function FormResponseDetailRequestPreview() {
  return (
    <div className="max-w-screen-2xl mx-auto">
      <div className="my-4 bg-white px-4 pb-4">
        <FormResponseDetailHeader showBack={false} />
        <FormResponseDetailFormDetailTabContent />
      </div>
    </div>
  );
}
