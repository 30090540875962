/* eslint-disable no-else-return */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFormDetailById, getFormResponseDetailById } from '../../redux/features/forms/formSlice';
import { FormType } from './config/forms/constant';
import FormResponseDetailRequestPreview from './components/FormResponsePreview/formResponseDetailRequestPreview';
import FormResponseDetailSurveyPreview from './components/FormResponsePreview/formResponseDetailSurveyPreview';

export default function formResponseDetailPreview() {
  const { form } = useSelector((state) => state.form);

  const { id, responseId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getFormDetailById(id));
    }

    if (responseId) {
      dispatch(getFormResponseDetailById(responseId));
    }
  }, [responseId, id]);

  if (form.form_type === FormType.Survey) {
    return <FormResponseDetailSurveyPreview />;
  } else if (form.form_type === FormType.Request) {
    return <FormResponseDetailRequestPreview />;
  } else {
    return null;
  }
}
