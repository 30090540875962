import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormPreviewHeader from '../FormPreview/FormPreviewHeader';
import {
  FormField,
  FormItem,
  FormControl,
  FormLabel,
  FormMessage,
} from '../../../../components/form/form';
import TextInput from '../../../../components/input/textInput';
import MailIcon from '../../../../assets/svg-icons/MailIcon';
import LockIcon from '../../../../assets/svg-icons/LockIcon';
import EyeIcon from '../../../../assets/svg-icons/EyeIcon';
import EyeOffIcon from '../../../../assets/svg-icons/EyeOffIcon';

export default function FormRequestSecureAccess() {
  const form = useFormContext();
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <>
      <FormPreviewHeader />

      <div className="bg-white rounded-2xl py-6 px-10 mx-auto mt-6">
        <div className="bg-neutral-color-aplha-neutral-8 rounded-[8px] px-2 py-3">
          <p className="font-semibold text-lg text-neutral-color-darker mb-0">Secure Access</p>
        </div>

        <div className="space-y-[10px] py-4 border-b border-b-neutral-color-divider">
          <div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="mb-2" isRequired>
                    Email
                  </FormLabel>
                  <FormControl>
                    <TextInput
                      prefix={<MailIcon />}
                      variant="filled"
                      placeholder="Enter the registered email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div>
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="mb-2" isRequired>
                    Password
                  </FormLabel>
                  <FormControl>
                    <TextInput
                      className="!border-neutral-color-divider border"
                      type={showPassword ? 'text' : 'password'}
                      prefix={<LockIcon />}
                      suffix={
                        showPassword ? (
                          <EyeIcon
                            onClick={() => setShowPassword(!showPassword)}
                            className="text-neutral-color-neutral-variant-color-main cursor-pointer"
                          />
                        ) : (
                          <EyeOffIcon
                            onClick={() => setShowPassword(!showPassword)}
                            className="text-neutral-color-neutral-variant-color-main cursor-pointer"
                          />
                        )
                      }
                      variant="filled"
                      placeholder="Enter the password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <p className="text-neutral-color-main text-base mt-4 mb-0">
          Not registered yet? Please contact us to have your account registered.
        </p>
      </div>
    </>
  );
}
