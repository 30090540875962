import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setAsset } from '../../redux/features/asset/assetSlice';
import classes from '../assetsPage/assestsPage.module.css';
import Pagination from '../../components/pagination/pagination';
import Loader from '../../components/loader/loader';
import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
// import PMTable from './Componets/PMTable';
import PMHeader from './Componets/PMHeader';
import {
  setTab,
  closeReminderModalCreate,
  closeReminderModalUpdate,
  getReminderById,
  // openReminderModalUpdate,
  reset,
  resetReminder,
  setReminder,
  setCurrentPage,
  setRowPerPage,
  getRemindersByPagination,
  getReminderSummary,
} from '../../redux/features/reminder/reminderSlice';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import PMTable from './Componets/PMTable';
import EditPMModal from './Componets/EditPMModal';
import AddEventModal from './Componets/AddEventModal';
import SummaryCards from '../../components/summaryCards/summaryCards';
// import PMScheduleTabs from './PMScheduleTabs';

/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */

export default function viewEvent() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTeamsByAuthentication());
  }, []);

  const {
    reminders,
    reminderSummary,
    reminderModalCreate,
    // reminderModalUpdate,
    isReminderCreateSuccess,
    isReminderDeleteSuccess,
    isReminderUpdateSuccess,
    isReminderCreateError,
    isReminderDeleteError,
    isReminderUpdateError,
    searchValue,
    currentPage,
    rowPerPage,
    pmSchedule,
    filterStartDate,
    filterEndDate,
  } = useSelector((state) => state.reminder);
  

  const pmDataCount = useSelector((state) => state.reminder?.reminders?.count);
  
  const [data, setData] = useState([]);
  const [iSShowFilter, setISShowFilter] = useState(false);
  const [iSShowCheckboxs, setISShowCheckboxs] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowPerPage, setRowPerPage] = useState(10);

  useEffect(() => {
    dispatch(getReminderSummary())
  }, []);

  const getDataRows = async () => {
    setIsLoading(true);
    const filters = {
      rowPerPage,
      currentPage,
      searchValue,
      startDate: filterStartDate ? moment(filterStartDate).format('YYYY-MM-DD') : '',
      endDate: filterEndDate ? moment(filterEndDate).format('YYYY-MM-DD') : '',
    };
    await dispatch(getRemindersByPagination(filters));
    setIsLoading(false);
  };

  useEffect(() => {
    getDataRows();
  }, [rowPerPage, currentPage, searchValue]);

  const [eventModalUpdate, setEventModalUpdate] = useState(false);
  const openEventModalUpdate = () => setEventModalUpdate(true);
  const closeEventModalUpdate = () => setEventModalUpdate(false);

  const getSelectedRowwValues = async (selectedRow, cell) => {
    if (cell.column.id !== 'delete-table-column') {
      dispatch(setReminder({}));
      dispatch(resetReminder());
      dispatch(setAsset(null));
      dispatch(selectActions.reset());
      await dispatch(getReminderById(selectedRow.id));
      // dispatch(openReminderModalUpdate())
      openEventModalUpdate();
      dispatch(setTab('service-tab'));
    }
  };
  
  useEffect(() => {
    const modifiedData = reminders?.results?.map((d) => {
      return {
        id: d?.id,
        title: d?.reminder_name,
        description: d?.description,
        reminderTiming: d?.reminder_timing,
        timeGap: d?.time_gap,
        createdBy: d?.created_by,
        assignedPersons: d?.assigned_to?.length > 0 ? d?.assigned_to?.map((a) => a) : '—',
        status: d?.progress,
        category: d?.category,
        startDate: moment.utc(d?.start_date).format('DD MMMM YY'),
        endDate: moment.utc(d?.end_date).format('DD MMMM YY'),
      };
    });
    setData(modifiedData);
  }, [reminders]);

  function displayToast() {
    if (isReminderCreateError) {
      toast.error('Something went wrong Creating Reminder', {
        toastId: 'CreatedReminderError',
      });
      dispatch(reset());
    } else if (isReminderUpdateError) {
      toast.error(' Something went wrong Updating Reminder', {
        toastId: 'UpdatingReminderError',
      });
      dispatch(reset());
    } else if (isReminderDeleteError) {
      toast.error(' Something went wrong Deleting Reminder', {
        toastId: 'DeletingReminderError',
      });
      dispatch(reset());
    } else if (isReminderCreateSuccess) {
      toast.success(' Reminder Created successfully', {
        toastId: 'Reminder Created Success',
      });
      dispatch(closeReminderModalCreate());
      dispatch(resetReminder());
      dispatch(reset());
      if (pmSchedule !== 'PM Schedule') {
        getDataRows();
      }
    } else if (isReminderDeleteSuccess) {
      toast.success(' Reminder Deleted successfully', {
        toastId: 'DeletedReminderError',
      });
      dispatch(reset());
      dispatch(resetReminder());
      dispatch(closeReminderModalUpdate());
    } else if (isReminderUpdateSuccess) {
      toast.success(' Reminder Updated successfully', {
        toastId: 'UpdatedReminderError',
      });
      dispatch(reset());
    }
  }
  return (
    <div>
      {displayToast()}
      <div className={classes.assets}>
        <PMHeader setISShowFilter={setISShowFilter} iSShowFilter={iSShowFilter} />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <SummaryCards title="Total Reminder" count={pmDataCount} />
              <SummaryCards title="Active" count={reminderSummary?.Active} color="rgba(32, 187, 125, 0.64)" />
              <SummaryCards title="Due soon" count={reminderSummary?.['Due Soon']} color="rgba(227, 135, 30, 0.64)" />
              <SummaryCards title="Overdue" count={reminderSummary?.Overdue} color="rgba(217, 49, 74, 0.64)" />
            </div>
            <PMTable
              iSShowFilter={iSShowFilter}
              setISShowFilter={setISShowFilter}
              iSShowCheckboxs={iSShowCheckboxs}
              setISShowCheckboxs={setISShowCheckboxs}
              getSelectedRowwValues={getSelectedRowwValues}
              meterData={data || []}
              setIsLoading={setIsLoading}
            />
            <Pagination
              totalRows={pmDataCount}
              rowPerPage={rowPerPage}
              pageChangeHandler={setCurrentPage}
              changeRowsPerPage={setRowPerPage}
              currentPage={currentPage}
            />
          </>
        )}
      </div>
      {reminderModalCreate && (
        <AddEventModal
          openModalCreate={reminderModalCreate}
          closeModalCreate={() => dispatch(closeReminderModalCreate())}
        />
      )}
      {eventModalUpdate && (
        <EditPMModal openModalUpdate={eventModalUpdate} closeModalUpdate={closeEventModalUpdate} />
      )}
    </div>
  );
}
