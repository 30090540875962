/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable  no-lone-blocks */
/* eslint-disable  no-unused-expressions */
/* eslint-disable  react/self-closing-comp */

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useTable, useExpanded, useGlobalFilter } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import classes from '../../../components/assetsTable/assetsTable.module.css';
import Loader from '../../../components/loader/loader';
import {
  closeReminderModalDelete,
  deleteReminder,
  openReminderModalDelete,
} from '../../../redux/features/reminder/reminderSlice';
import PopUpDeleteModal from '../../../components/popUpModals/editCategory/popUpDeleteModal';
import PMFilter from '../PMFilter';

function PMTable({
  meterData,
  getSelectedRowwValues,
  iSShowFilter,
  setISShowFilter,
  isLoading,
  setIsLoading,
}) {
  const [rowId, setRowId] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { reminderModalDelete } = useSelector((state) => state.reminder);
  const handleDelete = () => {
    dispatch(deleteReminder(rowId));
  };

  // const handleCheckboxChange = (selectedRow) => {
  //   const row = selectedRow.row.original;
  //   // Log the current state of billData
  //   const findIndex = billData.findIndex((item) => item.id === row.id);
  //   if (findIndex === -1) {
  //     dispatch(
  //       setBillData([
  //         ...billData,
  //         {
  //           ...row,
  //           discountRate: 0,
  //           quantity: 1,
  //           totalPrice: row?.rate,
  //           vendorQuantity: 1,
  //           vendorTotalPrice: row?.rate,
  //           remarkText: '',
  //         },
  //       ]),
  //     );
  //   } else {
  //     const filteredItem = billData.filter((item) => item.id !== row.id);
  //     dispatch(setBillData(filteredItem));
  //   }
  // };

  // const isChecked = (selectedRow) => {
  //   const row = selectedRow.row.original;
  //   const billDataCopy = [...billData];
  //   const findIndex = billDataCopy.findIndex((item) => item.id === row.id);
  //   if (findIndex !== -1) {
  //     return true;
  //   }
  //   return false;
  // };

  const columns = React.useMemo(
    () => [
      // {
      //   id: 'checkbox',
      //   Cell: (row) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         style={{ width: '20px', height: '20px' }}
      //         onChange={() => handleCheckboxChange(row)}
      //         checked={isChecked(row)}
      //       />
      //     );
      //   },
      // },
      {
        Header: 'Event ID',
        accessor: 'id',
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Description',

        accessor: 'description',
      },
      {
        Header: 'Start date',
        accessor: 'startDate',
      },
      {
        Header: 'End date',
        accessor: 'endDate',
      },
      {
        Header: 'Reminder',
        Cell: ({ row }) => {
          return (
            <span>
              {row?.original?.timeGap} {row?.original?.reminderTiming} before
            </span>
          );
        },
      },
      {
        Header: 'Created by',
        Cell: ({ row }) => {
          return (
            <span>
              {row?.original?.createdBy?.first_name} {row?.original?.createdBy?.last_name}
            </span>
          );
        },
      },
      {
        Header: 'Assigned To',
        Cell: ({ row }) => {
          return (
            <span>
              {row?.original?.assignedPersons !== '—' ? (
                row?.original?.assignedPersons?.map((item) => {
                  return (
                    <>
                      <span>{item?.name}</span> <br />
                    </>
                  );
                })
              ) : (
                <span className="ml-4">—</span>
              )}
            </span>
          );
        },
      },
      {
        Header: '%',
        Cell: ({ row }) => {
          const percentage = row?.original?.status?.percentage || 0; // Default to 0 if no percentage
          const status = row?.original?.status?.status;
          const dynamicStyles = {
            '--progress-width': `${percentage}%`,
            '--progress-color':
              status === 'Active'
                ? 'rgba(33, 217, 137, 0.32)'
                : status === 'Overdue'
                ? 'rgba(217, 33, 91, 0.32)'
                : 'rgba(217, 125, 33, 0.32)',
            '--progress-shadow':
              status === 'Active'
                ? '0px 0px 8px -1px rgba(33, 217, 137, 0.32)'
                : status === 'Overdue'
                ? '0px 0px 8px -1px rgba(217, 33, 91, 0.32)'
                : '0px 0px 8px -1px rgba(217, 125, 33, 0.32)',
          };
          return (
            <div style={{ width: '120px' }}>
              <div>{percentage}%</div>
              <div className={classes.tableProhressBar} style={dynamicStyles} />
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status.status',
      },
      {
        id: 'delete-table-column',
        Header: 'Action',
        Cell: ({ row }) => {
          let deleteBtn = (
            <span
              title="Delete PM"
              onClick={() => {
                dispatch(openReminderModalDelete());
                setRowId(row.original.id);
              }}
              // onClick={() => dispatch(deleteReminder(row.original.id))}
              id={row.id}
              className={`tertiary-btn btn ${classes['del-bt']} `}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </span>
          );
          if (
            // user?.role === 'Technical Team' ||
            // user?.role === 'Requester' ||
            // (user?.role === 'Manager' && row.original?.createdBy !== 'Manager')
            user?.role !== 'CliAdm'
          ) {
            deleteBtn = '';
          }
          return deleteBtn;
        },
      },
    ],
    [user],
  );

  const data = meterData;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, allColumns } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    useGlobalFilter,
    useExpanded,
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PMFilter
            setISShowFilter={setISShowFilter}
            iSShowFilter={iSShowFilter}
            allColumns={allColumns}
            setIsLoading={setIsLoading}
          />
          <div className={classes.scrollX}>
            <table {...getTableProps()} className={`${classes.table} ${classes.forScroll}`}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length !== 0 ? (
                  rows?.map((row) => {
                    prepareRow(row);
                    let statusClass;
                    if (row.original?.status?.status === 'Due Soon') {
                      statusClass = classes.inProgress;
                    }
                    if (row.original?.status?.status === 'Overdue') {
                      statusClass = classes.onHold;
                    }
                    if (row.original?.status?.status === 'Active') {
                      statusClass = classes.completed;
                    }
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              onClick={() => getSelectedRowwValues(row.original, cell)}
                              style={
                                cell.column?.Header === 'Description'
                                  ? { whiteSpace: 'wrap', minWidth: '250px', maxWidth: '250px' }
                                  : {}
                              }
                            >
                              {cell.column.Header === 'Status' ? (
                                row.original.status === 'Completed' ? (
                                  <span className={statusClass}>
                                    {cell.render('Cell')}
                                    {moment(row.original.completedDate).diff(
                                      moment(row?.original?.start_date).format('YYYY-MM-DD'),
                                      'seconds',
                                    ) < 0 ? (
                                      <i
                                        className="fa fa-check-circle ml-2"
                                        style={{ color: 'green' }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-check-circle ml-2"
                                        style={{ color: 'red' }}
                                      ></i>
                                    )}
                                  </span>
                                ) : (
                                  <span className={statusClass}>{cell.render('Cell')}</span>
                                )
                              ) : cell.column.Header === 'Service Date' ? (
                                <span style={{ color: '#db1f48' }}>{cell.render('Cell')}</span>
                              ) : (
                                <span>{cell.render('Cell')}</span>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    {' '}
                    <td className={classes.noResults} colSpan="9">
                      No Results Found{' '}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {reminderModalDelete && (
            <PopUpDeleteModal
              showModal={reminderModalDelete}
              handleClose={() => dispatch(closeReminderModalDelete())}
              handleConfirm={handleDelete}
              modalTitle="Delete reminder"
              modalBodyText="Are you sure you want to delete this reminder?"
            />
          )}
        </>
      )}
    </>
  );
}

export default PMTable;
