import React from 'react';
import { useSelector } from 'react-redux';

import Label from '../../../../components/label/label';
import TextInput from '../../../../components/input/textInput';

export default function FormResponseDetailRequesterInformation() {
  const { formResponseDetail } = useSelector((state) => state.form);

  const requesterInformation = formResponseDetail?.submitted_by;

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-base font-semibold text-neutral-color-darker">Requester information</h2>

      <div className="space-y-2">
        <Label>Name</Label>
        <TextInput variant="filled" disabled value={requesterInformation?.fullname || ''} />
      </div>

      <div className="grid md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label>Email</Label>
          <TextInput variant="filled" disabled value={requesterInformation?.email || ''} />
        </div>

        <div className="space-y-2">
          <Label>Phone Number</Label>
          <TextInput variant="filled" disabled value={requesterInformation?.phone_number} />
        </div>
      </div>

      <div className="space-y-2">
        <Label>Signature</Label>
        <div className="border border-neutral-color-divider bg-neutral-color-theme-color-light w-[130px] h-[130px] p-2 rounded-[8px]">
          <img
            className="w-full h-full"
            width={130}
            height={130}
            src={formResponseDetail?.requester_signature_url}
            alt="signature"
          />
        </div>
      </div>
    </div>
  );
}
