import { useSelector } from 'react-redux';

/* eslint-disable camelcase */

export default function useAccessControl() {
  const { profile } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.user);

  const canViewForm = profile?.subscription?.can_view_form && user?.can_view_form;
  const canViewReminder = profile?.subscription?.can_view_reminder && user?.can_view_reminder;
  const canViewAsset =
    profile?.subscription?.can_view_asset_management && user?.can_view_asset_management;
  const canViewEpq = profile?.subscription?.can_view_ebq && user?.can_view_ebq;

  return {
    canViewEpq,
    canViewForm,
    canViewReminder,
    canViewAsset,
  };
}
