import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import {
  setOpenPenaltyModal,
  setPenaltyData,
  setPenaltyTotalPrice,
} from '../../redux/features/SOR/SORSlice';
import PlusIcon from '../../assets/svg-icons/PlusIcon';
import './epq.css';
import AddNewPenaltyModal from './addNewPenaltyModal';
import DeleteIcon from '../../assets/svg-icons/DeleteIcon';
import Tooltip from '../../components/tooltip/tooltip';

/* eslint-disable no-unsafe-optional-chaining */

export default function Penalty({ pdf }) {
  const dispatch = useDispatch();
  const {
    openPenaltyModal,
    penaltyData,
    subTotal,
    penaltyTotalPrice,
    billWorkData,
    billWorkOrderTypes,
  } = useSelector((state) => state.SOR);

  React.useEffect(() => {
    const totalSumPenalty = penaltyData?.reduce((sum, p) => {
      return (
        sum +
        parseFloat(
          p?.penaltyQuantumType === 'price'
            ? p?.penaltyQuantumRate
            : (Number(p.penaltyQuantumRate) * subTotal?.subTotalVendor) / 100,
        )
      );
    }, 0);

    dispatch(setPenaltyTotalPrice(totalSumPenalty));
  }, [subTotal, penaltyData]);

  const deleteItemHandler = (index) => {
    dispatch(dispatch(setPenaltyData(penaltyData.filter((_, i) => i !== index))));
  };
  const handleOpenPenaltyModal = () => {
    if (billWorkData?.orderType) {
      dispatch(setOpenPenaltyModal(true));
    } else {
      toast.warning('Please choose Work order type first and try again', {
        toastId: 'CreatedEventWarning',
      });
    }
  };

  const getWoType = (parId) => {
    const woType = billWorkOrderTypes?.find((item) => item.id === +parId);
    return woType?.parameter;
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h5 className="mb-0">Penalty</h5>
        {!pdf && (
          <div className="choose-assets-location">
            <button type="button" onClick={handleOpenPenaltyModal}>
              <span className="mr-2">
                <PlusIcon color="#D9314A" />{' '}
              </span>{' '}
              Add penalty
            </button>
          </div>
        )}
      </div>
      <table className="bills-table" style={{ width: '100%', padding: '16px' }}>
        <thead>
          <tr>
            <td>Parameter</td>
            <td>
              Period/ <br />
              <span style={{ color: '#727478' }}>Periode</span>
            </td>
            <td>
              Total days/ <br />
              <span style={{ color: '#727478' }}>Jumlah hari</span>
            </td>
            <td>Penalty quantum</td>
            <td>
              Total price/ <br />
              <span style={{ color: '#727478' }}>Jumlah harga</span>
            </td>
            <td />
          </tr>
        </thead>
        <tbody>
          {penaltyData?.length > 0 ? (
            penaltyData?.map((item, index) => (
              <tr>
                <td>{getWoType(item?.parameter)}</td>
                <td>
                  {item?.startDate} - {item?.endDate}{' '}
                </td>
                <td>
                  {item?.startDate && item?.endDate
                    ? moment(item.endDate).diff(moment(item.startDate), 'days')
                    : '_'}
                </td>
                <td>
                  {item?.penaltyQuantumType === 'price' ? 'RM' : ''}
                  {item?.penaltyQuantumRate}
                  {item?.penaltyQuantumType === 'percentage' ? '%' : ''}
                </td>
                <td>
                  RM
                  {item?.penaltyQuantumType === 'price'
                    ? (+item?.penaltyQuantumRate)?.toLocaleString()
                    : (+(
                        (Number(item.penaltyQuantumRate) * subTotal?.subTotalVendor) /
                        100
                      )?.toFixed(2))?.toLocaleString()}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {!pdf && (
                    <IconButton
                      style={{
                        backgroundColor: '#F8F9F9',
                        borderRadius: '8px',
                        border: '1px solid #E1E1E1',
                        padding: '9px',
                      }}
                      variant="contained"
                      title="Delete penalty"
                      className="outlined-button mr-2"
                      onClick={() => deleteItemHandler(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: 'center' }}>
                No data
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="4" style={{ fontWeight: 600 }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!pdf && (
                  <Tooltip
                    color="black"
                    content={<span>Total Penalty = Sum ( total price)</span>}
                  />
                )}
                <span className="ml-2">Total Penalty</span>
              </div>
            </td>
            <td style={{ fontWeight: 600 }}>
              <span style={{ fontWeight: 700 }}>
                RM
                {parseFloat(penaltyTotalPrice)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </span>
            </td>
            <td />
          </tr>
        </tfoot>
      </table>
      {openPenaltyModal && <AddNewPenaltyModal />}
    </div>
  );
}
