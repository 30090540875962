/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useMemo, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTable } from 'react-table';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import classes from './assetsTable.module.css';
import Pagination from '../pagination/pagination';
import { getAssetHistoryById } from '../../redux/features/asset/assetSlice';
// import EditWOModal from '../../pages/workOrder/Componets/EditWOModal';
import {
  setTab as WOTab,
  getWorkOrderByTicketNumber,
  closeWorkOrderModalUpdate,
  openWorkOrderModalUpdate,
  reset as WOReset,
  resetWorkOrder,
  getPagination as getWOPagination,
} from '../../redux/features/workOrder/workOrderSlice';
// import { setTab } from '../../redux/features/meter/meterSlice';
import { getWorkOrderCategoryByAuthentication } from '../../redux/features/workOrderCategory/workOrderCategorySlice';
import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
import { getChecklistByAuthentication } from '../../redux/features/checklist/checklistSlice';
import { getInventorysByAuthentication } from '../../redux/features/inventory/inventorySlice';
// import EditPMModal from '../../pages/viewMaintenance/Componets/EditPMModal';
import {
  setTab as PMTab,
  closeMaintenanceModalUpdate,
  openMaintenanceModalUpdate,
  reset as PMReset,
  resetMaintenance,
  getPagination as getPMPagination,
  getMaintenanceById,
} from '../../redux/features/maintenance/maintenanceSlice';

export default function AssetHistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');

  const { assetHistory } = useSelector((state) => state.asset);
  const {
    // workOrderModalUpdate,
    isWorkOrderDeleteSuccess,
    isWorkOrderUpdateSuccess,
    isWorkOrderDeleteError,
    isWorkOrderUpdateError,
  } = useSelector((state) => state.workOrder);
  const {
    // maintenanceModalUpdate,
    isMaintenanceDeleteSuccess,
    isMaintenanceUpdateSuccess,
    isMaintenanceDeleteError,
    isMaintenanceUpdateError,
  } = useSelector((state) => state.maintenance);

  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getWorkOrderCategoryByAuthentication());
    dispatch(getTeamsByAuthentication());
    dispatch(getChecklistByAuthentication());
    dispatch(getInventorysByAuthentication());
  }, []);

  useEffect(() => {
    const getDataRows = async () => {
      // const page = JSON.stringify(currentPage)
      // const row = JSON.stringify(rowPerPage)
      const data = { id, currentPage, rowPerPage, searchValue };
      await dispatch(getAssetHistoryById(data));
    };

    getDataRows();
  }, [id, rowPerPage, currentPage, searchValue]);

  const handleIconClick = () => {
    history.push('/assets');
  };

  const getSelectedRowwValues = async (selectedRow, cell) => {
    const filters = {
      site: null,
      sublocation: null,
      filterCategory: null,
      type: null,
      staff: null,
      status: null,
      statusAdvance: '',
      asset: null,
      startDate: '',
      endDate: '',
    };
    if (selectedRow.reference != null) {
      if (cell.column.id === 'reference') {
        if (selectedRow.ticketType === 'workorder') {
          await dispatch(getWorkOrderByTicketNumber(selectedRow.reference));
          dispatch(
            getWOPagination({
              ...filters,
              rowPerPage: 10,
              currentPage: 1,
              searchValue: selectedRow.reference,
            }),
          );
          dispatch(openWorkOrderModalUpdate());
          dispatch(WOTab('service-tab'));
        }
      }
      if (selectedRow.ticketType === 'pm') {
        await dispatch(getMaintenanceById(selectedRow?.ticketId));
        dispatch(
          getPMPagination({
            ...filters,
            rowPerPage: 10,
            currentPage: 1,
            searchValue: selectedRow.reference,
          }),
        );
        dispatch(openMaintenanceModalUpdate());
        dispatch(PMTab('service-tab'));
      }
    }
  };

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'reference',
      },
      {
        Header: 'Date',
        accessor: 'history_date',
      },

      {
        Header: 'Activity',
        accessor: 'history_change_reason',
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
      },
    ],
    [],
  );

  const data2 = assetHistory?.results?.map((d) => {
    const date = new Date(d.history_date);
    const formattedDate = date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return {
      reference: d.reference,
      history_date: formattedDate,
      history_change_reason: d?.changes ? d?.changes : d?.history_change_reason,
      changes: d?.changes,
      updated_by: d.updated_by,
      ticketType: d?.ticket_type,
      ticketId: d?.ticket_id,
    };
  });

  const data = data2 || [];

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
    manualPagination: true,
  });

  const pmDataCount = assetHistory?.count;

  function displayToast() {
    if (isWorkOrderUpdateError) {
      toast.error(' Something went wrong Updating WorkOrder', {
        toastId: 'UpdatingWorkOrderError',
      });
      dispatch(WOReset());
    } else if (isWorkOrderDeleteError) {
      toast.error(' Something went wrong Deleting WorkOrder', {
        toastId: 'DeletingWorkOrderError',
      });
      dispatch(WOReset());
    } else if (isWorkOrderDeleteSuccess) {
      toast.success(' WorkOrder Deleted successfully', {
        toastId: 'DeletedWorkOrderError',
      });
      dispatch(WOReset());
      dispatch(resetWorkOrder());
      dispatch(closeWorkOrderModalUpdate());
    } else if (isWorkOrderUpdateSuccess) {
      toast.success(' WorkOrder Updated successfully', {
        toastId: 'UpdatedWorkOrderError',
      });
      dispatch(WOReset());
    } else if (isMaintenanceUpdateError) {
      toast.error(' Something went wrong Updating Maintenance', {
        toastId: 'UpdatingMaintenanceError',
      });
      dispatch(PMReset());
    } else if (isMaintenanceDeleteError) {
      toast.error(' Something went wrong Deleting Maintenance', {
        toastId: 'DeletingMaintenanceError',
      });
      dispatch(PMReset());
    } else if (isMaintenanceDeleteSuccess) {
      toast.success(' Maintenance Deleted successfully', {
        toastId: 'DeletedMaintenanceError',
      });
      dispatch(PMReset());
      dispatch(resetMaintenance());
      dispatch(closeMaintenanceModalUpdate());
    } else if (isMaintenanceUpdateSuccess) {
      toast.success(' Maintenance Updated successfully', {
        toastId: 'UpdatedMaintenanceError',
      });
      dispatch(PMReset());
    }
  }

  return (
    <>
      {displayToast()}
      <section className="content">
        <div className="row">
          <div className="col-lg-12 col-sm-12 ">
            <div className="card-header">
              <div role="presentation" onClick={handleIconClick}>
                <i className="fa fa-arrow-alt-circle-left fa-lg float-right ml-3 mt-2 pt-1 mb-2" />
              </div>
              <h4 className="card-title mt-2 medium-title">
                <b>Assets history</b>
              </h4>
              <input
                type="text"
                id="history-search"
                onChange={handleSearchValue}
                placeholder="Search"
                className="rgfloat search-bar p-1"
              />
            </div>
            <div className={classes.scrollX}>
              <table {...getTableProps()} className={classes.table}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.length !== 0 ? (
                    rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                onClick={() => getSelectedRowwValues(row.original, cell)}
                              >
                                {cell.column.Header === 'Activity' &&
                                row.original?.changes !== null ? (
                                  <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                                    {parse(row?.original?.history_change_reason)}
                                  </p>
                                ) : (
                                  cell.render('Cell')
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      {' '}
                      <td className={classes.noResults} colSpan="7">
                        No Results Found{' '}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalRows={pmDataCount}
              rowPerPage={rowPerPage}
              pageChangeHandler={setCurrentPage}
              changeRowsPerPage={setRowPerPage}
              currentPage={currentPage}
            />

            {/* <EditWOModal 
          openModalUpdate = {workOrderModalUpdate} 
          closeModalUpdate={() => dispatch(closeWorkOrderModalUpdate())}
        />
          { maintenanceModalUpdate && <EditPMModal 
          openModalUpdate = {maintenanceModalUpdate} 
          closeModalUpdate={() => dispatch(closeMaintenanceModalUpdate())}
        />} */}
          </div>
        </div>
      </section>
    </>
  );
}
