/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable  no-lone-blocks */
/* eslint-disable  no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable no-unsafe-optional-chaining */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTable, useExpanded, useGlobalFilter } from 'react-table';
import classes from './assetsTable.module.css';
import Loader from '../loader/loader';

function AssetsTable({
  assets,
  handelReset,
  getSelectedRowwValues,
  iSShowFilter,
  iSShowCheckboxs,
  setISShowCheckboxs,
  isLoading,
}) {
  const modifiedData = assets?.map((d) => {
    return {
      id: d?.id,
      uuid: d?.uuid,
      // if the asset length is longer than 28 characters we append it with ... and hide the extra elements else we display the full length
      asset_number:
        String(d?.asset_number).length > 28
          ? String(d?.asset_number).substring(0, 25) + '...'
          : d?.asset_number,
      asset_type: d?.asset_type?.name,
      status: d?.status,
      name: d?.name,
      assigned_to: d.assigned_to,
      category: d?.category?.name,
      created_at: d.created_at,
      purchased_date: d?.finance?.purchased_date,
      modified_at: d.modified_at,
      site: d?.location?.site
        ? `${d?.location?.site}${
            d?.sub_location?.room
              ? `${d?.sub_location?.room && `, ` + d?.sub_location?.room}${
                  d?.sub_location?.suite && `, ` + d?.sub_location?.suite
                }${d?.sub_location?.level && `, ` + d?.sub_location?.level}${
                  d?.sub_location?.building && `, ` + d?.sub_location?.building
                }`
              : ''
          }`
        : '—',
      mar: '10px',
      subRows:
        d?.children?.length === 0
          ? null
          : d.children.map((b) => {
              return {
                id: b?.id,
                uuid: b?.uuid,
                asset_number: b?.asset_number,
                asset_type: b?.asset_type?.name,
                status: b?.status,
                name: b?.name,
                assigned_to: b.assigned_to,
                category: b?.category?.name,
                created_at: b.created_at,
                purchased_date: b?.finance?.purchased_date,
                modified_at: b.modified_at,
                site: `${b?.location?.site}${
                  b?.sub_location?.room
                    ? `${b?.sub_location?.room && `, ` + b?.sub_location?.room}${
                        b?.sub_location?.suite && `, ` + b?.sub_location?.suite
                      }${b?.sub_location?.level && `, ` + b?.sub_location?.level}${
                        b?.sub_location?.building && `, ` + b?.sub_location?.building
                      }`
                    : ''
                }`,
                mar: '40px',
                subRows:
                  b?.children?.length === 0
                    ? null
                    : b.children.map((c) => {
                        return {
                          id: c?.id,
                          uuid: c?.uuid,
                          asset_number: c?.asset_number,
                          asset_type: c?.asset_type?.name,
                          status: c?.status,
                          name: c?.name,
                          assigned_to: c.assigned_to,
                          category: c?.category?.name,
                          created_at: c.created_at,
                          modified_at: c.modified_at,
                          purchased_date: c?.finance?.purchased_date,
                          site: `${c?.location?.site}${
                            c?.sub_location?.room
                              ? `${c?.sub_location?.room && `, ` + c?.sub_location?.room}${
                                  c?.sub_location?.suite && `, ` + c?.sub_location?.suite
                                }${c?.sub_location?.level && `, ` + c?.sub_location?.level}${
                                  c?.sub_location?.building && `, ` + c?.sub_location?.building
                                }`
                              : ''
                          }`,
                          mar: '70px',
                          subRows:
                            c?.children?.length === 0
                              ? null
                              : c.children.map((f) => {
                                  return {
                                    id: f?.id,
                                    uuid: f?.uuid,
                                    asset_number: f?.asset_number,
                                    asset_type: f?.asset_type?.name,
                                    status: f?.status,
                                    name: f?.name,
                                    assigned_to: f.assigned_to,
                                    category: f?.category?.name,
                                    created_at: f.created_at,
                                    modified_at: f.modified_at,
                                    purchased_date: f?.finance?.purchased_date,
                                    site: `${f?.location?.site}${
                                      f?.sub_location?.room
                                        ? `${
                                            f?.sub_location?.room && `, ` + f?.sub_location?.room
                                          }${
                                            f?.sub_location?.suite && `, ` + f?.sub_location?.suite
                                          }${
                                            f?.sub_location?.level && `, ` + f?.sub_location?.level
                                          }${
                                            f?.sub_location?.building &&
                                            `, ` + f?.sub_location?.building
                                          }`
                                        : ''
                                    }`,
                                    mar: '100px',
                                    add: 'no',
                                  };
                                }),
                        };
                      }),
              };
            }),
    };
  });

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        // Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        //   <span {...getToggleAllRowsExpandedProps()}>
        //     {isAllRowsExpanded ? '👇' : '👉'}
        //   </span>
        // ),

        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row

          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  // paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
          ) : null,
      },

      {
        Header: 'Asset ID',
        accessor: 'asset_number',
        autoResetHiddenColumns: true,
      },

      {
        Header: 'Name',
        accessor: 'name',
      },

      {
        Header: 'Category',
        accessor: 'category',
      },

      {
        Header: 'Type',
        accessor: 'asset_type',
      },

      {
        Header: 'Status',
        accessor: 'status.name',
      },
      {
        Header: 'Assigned To',
        Cell: ({ row }) => {
          // return moment(row?.original?.created_at).format('YYYY-MM-DD');
          return row?.original?.assigned_to?.length === 0
            ? '_'
            : row?.original?.assigned_to?.map((item) => (
                <>
                  <span>
                    {item?.name}
                  </span>{' '}
                  <br />
                </>
              ));
        },
      },

      {
        Header: 'Location',
        accessor: 'site',
      },

      {
        Header: 'Created Date',
        Cell: ({ row }) => {
          return moment(row?.original?.created_at).format('YYYY-MM-DD');
        },
      },

      {
        Header: 'Purchased Date',
        Cell: ({ row }) => {
          return moment(row?.original?.purchased_date).format('YYYY-MM-DD');
        },
      },
    ],
    [],
  );
  const [data, setData] = useState(modifiedData || []);
  useEffect(() => {
    setData(modifiedData || []);
  }, [assets]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, allColumns } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    useGlobalFilter,
    useExpanded,
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {iSShowFilter && (
            <div className={classes.filterTable}>
              <div className={classes.wraper}>
                <h4>Table columns</h4>
                <button type="button" onClick={() => setISShowCheckboxs(!iSShowCheckboxs)}>
                  <FontAwesomeIcon icon={faAngleDown} />
                </button>
              </div>
              {iSShowCheckboxs && (
                <div className={classes.checkboxs}>
                  {/* Loop through columns data to create checkbox */}
                  {allColumns.map((column) => {
                    if (column.id !== 'expander' && column.id !== 'asset_number') {
                      return (
                        <div key={column.id} className={classes.checkbox}>
                          <input type="checkbox" {...column.getToggleHiddenProps()} />
                          <span>{column.Header}</span>
                        </div>
                      );
                    }
                  })}
                  <br />
                </div>
              )}
            </div>
          )}

          <div className={classes.scrollX}>
            <table {...getTableProps()} className={classes.table}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length !== 0 ? (
                  rows?.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              onClick={() => getSelectedRowwValues(row.original, cell)}
                            >
                              {cell.column.Header === 'Serial/Barcode #' ? (
                                <span style={{ paddingLeft: row.original.mar }}>
                                  {cell.render('Cell')}
                                </span>
                              ) : (
                                <span>{cell.render('Cell')}</span>
                              )}
                            </td>
                          );
                        })}

                        {!row.original.add && (
                          <td onClick={handelReset} title="Add Child Asset">
                            <Link
                              to={`/addAsset/?parent_id=${row.original.id}`}
                              className={classes.addChild}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Link>
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    {' '}
                    <td className={classes.noResults} colSpan="6">
                      No Results Found{' '}
                    </td>{' '}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default AssetsTable;
