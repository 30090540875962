/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */

import React, { useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import './EditPMModal.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAssignedTo,
  setTitle,
  setDescription,
  setShowReminderForm,
  setStartDate,
  setEndDate,
  setTimeGap,
  setReminderTiming,
  updateReminder,
} from '../../../redux/features/reminder/reminderSlice';
import { SelectPersons } from '../../../components/select/select';

function EditPMModal({ openModalUpdate, closeModalUpdate }) {
  const { user } = useSelector((state) => state.user);

  const {
    reminder,
    title,
    description,
    assigned_to,
    showReminderForm,
    startDate,
    endDate,
    timeGap,
    reminderTiming,
  } = useSelector((state) => state.reminder);

  const dispatch = useDispatch();

  const convertDate = (date) => {
    return moment.utc(date).format('YYYY-MM-DDTHH:mm');
  };
  useEffect(() => {
    dispatch(
      setAssignedTo(
        reminder?.assigned_to?.length > 0
          ? reminder?.assigned_to?.map((i) => ({
              value: i?.id,
              label: `${i?.name} (${i?.role === 'CliAdm' ? 'Admin' : i?.role})`,
              image: i?.image,
            }))
          : [],
      ),
    );
    dispatch(setTitle(reminder?.reminder_name));
    dispatch(setDescription(reminder?.description));
    dispatch(setReminderTiming(reminder?.reminder_timing));
    dispatch(setTimeGap(reminder?.time_gap));
    dispatch(setShowReminderForm(reminder?.on_reminder));
    dispatch(setStartDate(convertDate(reminder?.start_date)));
    dispatch(setEndDate(convertDate(reminder?.end_date)));
  }, [reminder]);

  const handelTitle = (e) => {
    dispatch(setTitle(e.target.value));
  };

  const handelDescription = (e) => {
    dispatch(setDescription(e.target.value));
  };
  const handelStartDate = (e) => {
    dispatch(setStartDate(e.target.value));
  };
  const handelEndDate = (e) => {
    dispatch(setEndDate(e.target.value));
  };
  const handelTimeGap = (e) => {
    dispatch(setTimeGap(e.target.value));
  };
  const handelReminderTiming = (e) => {
    dispatch(setReminderTiming(e.target.value));
  };

  const teams = useSelector((state) => state.teams?.teams?.results);

  const handelSubmit = async (e) => {
    e.preventDefault();
    const data = {
      id: reminder?.id,
      reminder_name: title,
      description,
      start_date: moment.utc(startDate).format('YYYY-MM-DDTHH:mm:ss.SSZ'),
      end_date: moment.utc(endDate).format('YYYY-MM-DDTHH:mm:ss.SSZ'),
      // category: 'personal',
      on_reminder: showReminderForm,
      time_gap: timeGap || 0,
      reminder_timing: reminderTiming || null,
      assigned_to: assigned_to?.map((a) => a?.value),
    };
    await dispatch(updateReminder(data));
  };

  return (
    <Drawer
      anchor="right"
      open={openModalUpdate}
      onClose={closeModalUpdate}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      <div className="h-100 w-100">
        <div className="navbar col-lg-12 m-0 modal-header-background pr-0">
          <div className="col-12 mb-2 row px-2">
            <div className="my-auto">
              <h1 style={{ fontSize: '24px', marginTop: '10px' }}>Reminder details</h1>
            </div>
            <div className="row ml-auto">
              <div>
                <button
                  onClick={() => closeModalUpdate()}
                  type="button"
                  className="btn secondary-btn ml-2"
                  data-dismiss="modal"
                >
                  <div className="fas fa-times"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="" style={{ minHeight: '78.5%' }}>
            <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
              <div
                id="maintenance-tab"
                role="tabpanel"
                aria-labelledby="custom-tabs-two-maintenance-tab"
              >
                <div className="card-body">
                  <div className="row pb-5">
                    <div className="col-lg-12 col-sm-12 pt-1">
                      <label className="font-weight-normal">
                        Reminder title <label className="font-danger m-0">*</label>
                      </label>
                      <input
                        required
                        name="meter_name"
                        type="text"
                        className="form-control mb-2 rounded-md the-fixed-input"
                        placeholder="Add reminder title"
                        id="meter_name"
                        value={title}
                        onChange={handelTitle}
                        disabled={user?.role !== 'CliAdm'}
                      />
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="meter_error"></span>
                      </div>
                    </div>

                    <div className="col-lg-12 col-sm-12 pt-2">
                      <label className="font-weight-normal">Description</label>
                      <textarea
                        name="description"
                        className="form-control mb-2 the-fixed-input"
                        placeholder="Add description"
                        id="description"
                        onChange={handelDescription}
                        value={description}
                        style={{ height: '100px' }}
                        disabled={user?.role !== 'CliAdm'}
                      ></textarea>
                    </div>

                    <div className=" col-lg-12 col-md-12 col-sm-12 pt-3">
                      <label className="font-weight-normal">Start date</label>
                      <input
                        type="datetime-local"
                        className="form-control mb-2 the-fixed-input"
                        name="service_date"
                        id="meter_service_date"
                        placeholder="Start Date"
                        required
                        value={startDate}
                        onChange={handelStartDate}
                        disabled={user?.role !== 'CliAdm'}
                      />
                    </div>

                    <div className=" col-lg-12 col-md-12 col-sm-12 pt-3">
                      <label className="font-weight-normal">
                        End date<label className="font-danger m-0">*</label>
                      </label>
                      <input
                        type="datetime-local"
                        className="form-control mb-2 the-fixed-input"
                        name="service_date"
                        id="PM_service_date"
                        placeholder="End Date"
                        required
                        value={endDate}
                        onChange={handelEndDate}
                        disabled={user?.role !== 'CliAdm'}
                      />
                    </div>
                    <div className="col-lg-12 col-sm-12 pt-2">
                      <label className="font-weight-normal">Assigned to</label>
                      <div className="col-12 p-0">
                        <SelectPersons
                          teams={teams}
                          assignedTo={assigned_to}
                          setAssignedTo={setAssignedTo}
                          stopped={user?.role !== 'CliAdm'}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 pt-1">
                      <hr />
                    </div>
                    <div className="col-lg-12 col-sm-12 pt-2 pb-2">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                          <label className="weekly-frequency-switch black-switch mr-3">
                            <input
                              id="checkbox-toggle"
                              type="checkbox"
                              onChange={() => dispatch(setShowReminderForm(!showReminderForm))}
                              checked={showReminderForm}
                              disabled={user?.role !== 'CliAdm'}
                            />
                            <span className="weekly-frequency-slider" />
                          </label>
                        </span>
                        <label htmlFor="checkbox-toggle" className="font-weight-normal">
                          On reminder
                        </label>
                      </div>
                    </div>
                    {showReminderForm && (
                      <>
                        <div className="col-lg-12 col-sm-12 pt-2">
                          <label className="font-weight-normal mb-2">Set Alert Preference</label>
                        </div>
                        <div className="col-lg-6 col-sm-12 pt-2">
                          <input
                            required
                            name="interval"
                            type="text"
                            className="form-control mb-2 rounded-md the-fixed-input"
                            placeholder="0"
                            id="interval"
                            value={timeGap}
                            onChange={handelTimeGap}
                            disabled={user?.role !== 'CliAdm'}
                          />
                        </div>
                        <div className="col-lg-6 col-sm-12 pt-2">
                          <select
                            className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md"
                            name="reminder_timing"
                            id="reminder_timing"
                            onChange={handelReminderTiming}
                            value={reminderTiming}
                            disabled={user?.role !== 'CliAdm'}
                          >
                            <option value="">Select</option>
                            {[
                              { label: 'Hours before', value: 'hours' },
                              { label: 'Days before', value: 'days' },
                              { label: 'Weeks before', value: 'weeks' },
                              { label: 'Months before', value: 'months' },
                            ].map((type) => (
                              <option value={type.value} key={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" row m-0 p-2 page-profile-footer">
          <Button
            onClick={handelSubmit}
            // className="btn primary-btn"
            type="submit"
            variant="contained"
            style={{
              backgroundColor: '#36383C',
              color: '#F8F9F9',
              fontSize: '14px',
              fontWeight: '600',
              padding: '8px 16px',
              borderRadius: '8px',
            }}
            className="adv-button"
            disabled={user?.role !== 'CliAdm'}
          >
            Save
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

export default EditPMModal;
