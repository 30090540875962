import React from 'react';

export default function CopyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      className="text-[#36383C]"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeWidth={2}
        d="M11.201 12c.747 0 1.12 0 1.406-.146.25-.127.455-.331.582-.582.146-.286.146-.659.146-1.406v-6.4c0-.746 0-1.12-.146-1.405a1.333 1.333 0 0 0-.582-.583c-.286-.145-.659-.145-1.406-.145H7.468c-.747 0-1.12 0-1.405.145-.251.128-.455.332-.583.583-.145.285-.145.659-.145 1.405m-.534 11.2h3.734c.746 0 1.12 0 1.405-.145.25-.128.455-.332.583-.583.145-.285.145-.658.145-1.405v-6.4c0-.747 0-1.12-.145-1.405a1.333 1.333 0 0 0-.583-.583C9.655 4 9.281 4 8.535 4H4.8c-.746 0-1.12 0-1.405.145-.25.128-.455.332-.583.583-.145.285-.145.658-.145 1.405v6.4c0 .747 0 1.12.145 1.405.128.251.332.455.583.583.285.145.659.145 1.405.145Z"
      />
    </svg>
  );
}
