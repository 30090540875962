/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */

import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import './NotFound.css'; // External CSS for better styling
// import { useSelector } from 'react-redux';
import useAccessControl from '../../hooks/useAccessControl';

function NotFoundPage() {
  const { canViewForm, canViewReminder, canViewEpq, canViewAsset } = useAccessControl();  
  return (
    <Redirect
      to={
        canViewEpq
          ? '/ebq'
          : canViewForm
          ? '/forms'
          : canViewReminder
          ? '/event'
          : canViewAsset
          ? '/'
          : '/login'
      }
    />
  );
}

export default NotFoundPage;

export function NotFound() {
  const { canViewForm, canViewReminder, canViewEpq, canViewAsset } = useAccessControl();
  const notHaveAnyAccess = !canViewForm && !canViewReminder && !canViewEpq && !canViewAsset;
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404</h1>
      <p className="not-found-subtitle">Oops! The page you're looking for doesn't exist.</p>
      <p className="not-found-description">
        It might have been removed, had its name changed, or is temporarily unavailable.
      </p>
      {!notHaveAnyAccess && <Link
        to={
          canViewEpq
            ? '/ebq'
            : canViewForm
            ? '/forms'
            : canViewReminder
            ? '/event'
            : canViewAsset
            ? '/'
            : '/login'
        }
        className="not-found-link"
      >
        Go Back to Home
      </Link>}
    </div>
  );
}
