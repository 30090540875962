/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { startOfWeek, endOfWeek } from 'date-fns';
// import { IconButton } from '@material-ui/core';
import PlannerTabs from './PlannerTabs';
// import IconPlus from '../workOrder/Componets/icons/IconPlus';
import Calendar from '../../components/calendar/calendar';
import {
  closeEventModalCreate,
  closeEventModalUpdate,
  // getEventById,
  // openEventModalUpdate,
  resetEvent,
  setEvent,
  setEvents,
  getEventsByFilters,
} from '../../redux/features/dashboard/dashboardSlice';
import { getAssetListSearch, setAsset } from '../../redux/features/asset/assetSlice';
import { getWorkOrderCategoryByAuthentication } from '../../redux/features/workOrderCategory/workOrderCategorySlice';
import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
import { getMaintenanceCategoryByAuthentication } from '../../redux/features/maintenanceCategory/maintenanceCategorySlice';
import { getLocationsByAuthentication } from '../../redux/features/location/locationSlice';
import './dashboard.scss';
import Loader from '../../components/loader/loader';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import { getMeterCategoryByAuthentication } from '../../redux/features/meterCategory/meterCategorySlice';
import {
  // closeMaintenanceModalUpdate,
  getPagination as getPaginationPM,
  openMaintenanceModalUpdate,
  setTab as pmTab,
} from '../../redux/features/maintenance/maintenanceSlice';
import {
  getMaintenanceById,
  // closeWorkOrderModalUpdate,
  getWorkOrderById,
  getPagination as getPaginationWO,
  openWorkOrderModalUpdate,
  setDueDate,
  setWorkOrder,
  resetWorkOrder,
  setOpenFrom,
} from '../../redux/features/workOrder/workOrderSlice';
import {
  getMeterById,
  openMeterModalUpdate,
  getMetersByPagination,
  setTab as meterTab,
} from '../../redux/features/meter/meterSlice';
import TaskPlanner from './TaskPlanner';
import MaintenancePlanner from './MaintenancePlanner';

export default function Dashboard() {
  const dispatch = useDispatch();
  const {
    events,
    // eventModalCreate,
    isEventCreateLoading,
    isEventUpdateLoading,
    // eventModalUpdate,
    filterFeatures
  } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);

  const today = new Date()

  const fetchData = async () => {
    dispatch(getWorkOrderCategoryByAuthentication());
    dispatch(getMaintenanceCategoryByAuthentication());
    dispatch(getMeterCategoryByAuthentication());
    dispatch(getTeamsByAuthentication());
    dispatch(getLocationsByAuthentication());
    dispatch(getAssetListSearch());
  };

  const filterFunction = async () => {
    const queryString = filterFeatures
      .filter(feature => feature.selected) // Only keep selected items
      .map(feature => `feature=${feature.label}`)
      .join('&');
    setIsLoading(true)
    const filters = {
      startDate: moment(startOfWeek(today)).format("YYYY-MM-DD"),
      endDate: moment(endOfWeek(today)).format("YYYY-MM-DD"),
      feature: queryString
    }
    await dispatch(getEventsByFilters(filters));
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    dispatch(closeEventModalCreate());
    dispatch(resetEvent());
  };
  const handleUpdateClose = () => dispatch(closeEventModalUpdate());
  const [date, setDate] = useState('');

  const getSelectedTickect = async (id, ticketType, ticketTitle) => {
    const filterData = {
      site: '',
      sublocation: '',
      filterCategory: '',
      type: '',
      staff: '',
      status: '',
      asset: '',
    };
    if (ticketType === 'pm') {
      dispatch(setWorkOrder({}))
      dispatch(resetWorkOrder())
      dispatch(setAsset(null))
      dispatch(dispatch(selectActions.reset()))
      await dispatch(getMaintenanceById(id));
      dispatch(
        getPaginationPM({
          rowPerPage: 10,
          currentPage: 1,
          searchValue: ticketTitle,
          ...filterData,
        }),
      );
      dispatch(openWorkOrderModalUpdate());
      dispatch(setOpenFrom("Maintenance"));
    } else if (ticketType === 'workorder') {
      dispatch(setWorkOrder({}))
      dispatch(resetWorkOrder())
      dispatch(setAsset(null))
      dispatch(dispatch(selectActions.reset()))
      await dispatch(getWorkOrderById(id));
      dispatch(
        getPaginationWO({
          rowPerPage: 10,
          currentPage: 1,
          searchValue: ticketTitle,
          ...filterData,
        }),
      );
      dispatch(openWorkOrderModalUpdate());
      // dispatch(woTab('service-tab'));
    } else if (ticketType === 'meter') {
      await dispatch(getMeterById(id));
      dispatch(getMetersByPagination({ rowPerPage: 10, currentPage: 1, SearchVal: ticketTitle }));
      dispatch(openMeterModalUpdate());
      dispatch(meterTab('service-tab'));
    }
  };

  const handleEventClick = async (arg) => {
    dispatch(setEvent(null));
    dispatch(setAsset(null));
    dispatch(resetEvent());
    setDate('');
    dispatch(setDueDate(''));
    dispatch(selectActions.reset());
    getSelectedTickect(
      arg.event.extendedProps.eventId,
      arg.event.extendedProps.ticket_type,
      arg.event.extendedProps.name,
    );
  };

  const getEventColor = (status) => {
    switch (status) {
      case 'Open':
        return '#6591AC';
      case 'In-Progress':
        return '#FF8633';
      case 'On-Hold':
        return '#C23B22';
      case 'Completed':
        return '#4EA72E';
      default:
        return 'black';
    }
  };
  // Modify your eventStyle function to ensure unique IDs
  const eventStyle = (ev) => {
    return {
      ...ev,
      id: `${ev.id}${ev.ticket_type}`, // Append index to ensure uniqueness
      eventId: ev.id,
      title: `${moment.utc(ev.due_finish ? ev?.due_finish : ev?.reading_date).format('h:mm a')} - ${ev.title
        }`,
      date: moment
        .utc(new Date(ev.due_finish ? ev?.due_finish : ev?.reading_date))
        .format('YYYY-MM-DD'),
      backgroundColor: getEventColor(ev?.status || ''),
      borderColor: 'white',
      borderRadius: '10px',
      name: ev.title,
    };
  };

  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    setEventsData(events && events.length > 0 && events.map((ev, index) => eventStyle(ev, index)));
  }, [events]);

  const { meter } = useSelector((state) => state.meter);
  const { workOrder } = useSelector((state) => state.workOrder);

  useEffect(() => {
    if (meter?.id) {
      dispatch(
        setEvents(
          events?.map((item) =>
            item.id === meter?.id && item?.ticket_type === 'meter' ? meter : item,
          ),
        ),
      );
    }
  }, [meter]);


  useEffect(() => {
    if (workOrder?.id) {
      dispatch(
        setEvents(
          events?.map((item) =>
            item.id === workOrder?.id && (item?.ticket_type === 'workorder' || item?.ticket_type === 'pm') ? workOrder : item,
          ),
        ),
      );
    }
  }, [workOrder]);

  const [selectedMenu, setSelectedMenu] = useState('default');
  // Select view mode (board, calendar, etc.)
  const [selectedViewMode, setSelectedViewMode] = useState('Calendar mode');


  const handleViewMode = (event) => {
    setSelectedViewMode(event.target.value);
  };

  return (
    <>
      <div style={{ margin: '8px 16px', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
          <div style={{ fontSize: '24px', fontWeight: '600' }}>Planner</div>
          <PlannerTabs selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
        </div>
        <div style={{ display: 'flex', gap: '8px', height: 'fit-content' }}>
          <select
            // className="form-control select-dash custom-select2 mb-2"
            className="planner_select_mode"
            name="planner_mode"
            id="planner_mode"
            value={selectedViewMode}
            onChange={handleViewMode}
            style={{width: "150px"}}
          >
            {['Calendar mode', 'Board mode'].map((val) => (
              <option key={val} value={val}>
                {val}
              </option>
            ))}
          </select>
        </div>
      </div>

      {selectedMenu === 'default' && (
        <div className="calender-wraper">
          <h4 className="dash-title">
            <span>Welcome, </span>
            {user && user.first_name}
          </h4>
          {isEventCreateLoading || isEventUpdateLoading ? (
            <Loader />
          ) : (
            <div className="calender">
              <Calendar
                events={eventsData || []}
                // handleDateClick={handleDateClick}
                handleEventClick={handleEventClick}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
      )}

      {selectedMenu === 'task_planner' && (
        <TaskPlanner selectedMenu={selectedMenu} selectedViewMode={selectedViewMode} filterFunction={filterFunction} />
      )}

      {selectedMenu === 'maintenance_planner' && (
        <MaintenancePlanner selectedMenu={selectedMenu} selectedViewMode={selectedViewMode} />
      )}
    </>
  );
}
