import React from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBillWorkOrderType,
  setBillWorkData,
  setSummaryBillWorkData,
  setWorkDataErrors,
} from '../../redux/features/SOR/SORSlice';

const inputStyle = 'block w-full px-2 py-2 bg-white border border-gray-300 rounded-md mb-2';
const errorStyle = 'work-data-error';

function ShowError({ label }) {
  return (
    <div className=" text-danger" style={{ fontSize: '14px' }}>
      Please fill in {label}
    </div>
  );
}

function WorkData({ billSummary }) {
  const { billWorkData, summaryBillWorkData, workDataErrors } = useSelector((state) => state.SOR);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    dispatch(setBillWorkData({ ...billWorkData, [e.target.name]: e.target.value }));
    if (e.target.name === 'orderType') {
      dispatch(getBillWorkOrderType(e.target.value));
    }
    dispatch(setWorkDataErrors({ ...workDataErrors, [e.target.name]: false }));
  };

  const handleSummaryInputChange = (e) => {
    dispatch(setSummaryBillWorkData({ ...summaryBillWorkData, [e.target.name]: e.target.value }));
    dispatch(setWorkDataErrors({ ...workDataErrors, [e.target.name]: false }));
  };
  const handleSummaryDateInputChange = (e) => {
    const { value, name } = e.target;
    const newDate = new Date(value);
    // Validate dates if the changed field is startDate or endDate
    if (name === 'startDate' || name === 'endDate') {
      const otherDate =
        name === 'startDate' ? summaryBillWorkData.endDate : summaryBillWorkData.startDate;

      const otherDateObj = otherDate ? new Date(otherDate) : null;

      // Validation for date ranges
      if (name === 'startDate' && otherDateObj && newDate > otherDateObj) {
        toast.warning('Start date cannot be after the end date', { toastId: 'StartDateWarning' });
        return;
      }

      if (name === 'endDate' && otherDateObj && newDate < otherDateObj) {
        toast.warning('End date cannot be before the start date', { toastId: 'EndDateWarning' });
        return;
      }
      dispatch(setSummaryBillWorkData({ ...summaryBillWorkData, [name]: value }));
    }
  };

  return (
    <section className="work-info row p-3">
      {billSummary ? (
        <>
          <div className="col-lg-12 col-sm-12">
            <label style={{ fontSize: '14px' }} className="font-weight-normal">
              Work title / Tajuk kerja <label className="font-danger m-0">*</label>
            </label>
            {workDataErrors.title && <ShowError label="Work title / Tajuk kerja" />}
            <input
              required
              name="title"
              type="text"
              className={`${inputStyle} ${workDataErrors.title ? errorStyle : ''}`}
              value={summaryBillWorkData.title}
              onChange={handleSummaryInputChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Technician name / Nama juruteknik
            </label>
            <input
              required
              name="technicianName"
              type="text"
              className={inputStyle}
              value={summaryBillWorkData.technicianName}
              onChange={handleSummaryInputChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Contractor name / Nama kontraktor
            </label>
            <input
              required
              name="contractorName"
              type="text"
              className={inputStyle}
              value={summaryBillWorkData.contractorName}
              onChange={handleSummaryInputChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              No vendor
            </label>
            <input
              required
              name="noVendor"
              type="text"
              className={inputStyle}
              value={summaryBillWorkData.noVendor}
              onChange={handleSummaryInputChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Period
            </label>
            <div className="col-lg-12 col-sm-12 row">
              <div className="col-lg-6 col-sm-6">
                <input
                  name="startDate"
                  className={inputStyle}
                  type="date"
                  value={summaryBillWorkData.startDate}
                  onChange={handleSummaryDateInputChange}
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <input
                  name="endDate"
                  className={inputStyle}
                  type="date"
                  value={summaryBillWorkData.endDate}
                  onChange={handleSummaryDateInputChange}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Job no <label className="font-danger m-0">*</label>
            </label>
            {workDataErrors.jobNo && <ShowError label="Job no" />}
            <input
              required
              name="jobNo"
              type="text"
              className={`${inputStyle} ${workDataErrors.jobNo ? errorStyle : ''}`}
              value={summaryBillWorkData.jobNo}
              onChange={handleSummaryInputChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Purchase order number / No pesanan belian
            </label>
            <input
              required
              name="purchaseOrderNumber"
              type="text"
              className={inputStyle}
              value={summaryBillWorkData.purchaseOrderNumber}
              onChange={handleSummaryInputChange}
            />
          </div>
        </>
      ) : (
        <>
          <div className="col-lg-12 col-sm-12">
            <label style={{ fontSize: '14px' }} className="font-weight-normal">
              Work title / Tajuk kerja <label className="font-danger m-0">*</label>
            </label>
            {workDataErrors.title && <ShowError label="Work title / Tajuk kerja" />}
            <input
              required
              name="title"
              type="text"
              className={`${inputStyle} ${workDataErrors.title ? errorStyle : ''}`}
              value={billWorkData.title}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-12 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Work description / Keterangan kerja <label className="font-danger m-0">*</label>
            </label>
            {workDataErrors.description && (
              <ShowError label="Work description / Keterangan kerja" />
            )}
            <textarea
              required
              name="description"
              type="text"
              className={`${inputStyle} ${workDataErrors.description ? errorStyle : ''}`}
              value={billWorkData.description}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Outline agreement no <label className="font-danger m-0">*</label>
            </label>
            {workDataErrors.agreementNo && <ShowError label="Outline agreement no" />}
            <input
              required
              name="agreementNo"
              type="text"
              className={`${inputStyle} ${workDataErrors.agreementNo ? errorStyle : ''}`}
              value={billWorkData.agreementNo}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Job num / No job <label className="font-danger m-0">*</label>
            </label>
            {workDataErrors.jobNum && <ShowError label="Job num / No job" />}
            <input
              required
              name="jobNum"
              type="text"
              className={`${inputStyle} ${workDataErrors.jobNum ? errorStyle : ''}`}
              value={billWorkData.jobNum}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Work order (CMMS) / Perintah kerja <label className="font-danger m-0">*</label>
            </label>
            {workDataErrors.order && <ShowError label="Work order (CMMS) / Perintah kerja" />}
            <input
              required
              name="order"
              type="text"
              className={`${inputStyle} ${workDataErrors.order ? errorStyle : ''}`}
              value={billWorkData.order}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Work order type <label className="font-danger m-0">*</label>
            </label>
            {workDataErrors.orderType && <ShowError label="work order type" />}
            <select
              className={`${inputStyle} ${workDataErrors.orderType ? errorStyle : ''}`}
              name="orderType"
              value={billWorkData.orderType}
              onChange={handleInputChange}
            >
              <option value="" selected disabled>
                --SELECT--
              </option>
              <option value="sla">SLA</option>
              <option value="non-sla">NON-SLA</option>
            </select>
          </div>
          <div className="col-lg-6 col-sm-12">
            <label className="font-weight-normal" style={{ fontSize: '14px' }}>
              Location / Lokasi <label className="font-danger m-0">*</label>
            </label>
            {workDataErrors.location && <ShowError label="Location / Lokasi" />}
            <input
              required
              name="location"
              type="text"
              className={`${inputStyle} ${workDataErrors.location ? errorStyle : ''}`}
              value={billWorkData.location}
              onChange={handleInputChange}
            />
          </div>
        </>
      )}
    </section>
  );
}

export default WorkData;
