import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import WorkData from '../workData';
import {
  createBillSummary,
  getBillsSummaryById,
  reset,
  resetBillSummaryState,
  setSORTab,
  setIsBillSummaryPrint,
  updateBillSummary,
  setWorkDataErrors,
} from '../../../redux/features/SOR/SORSlice';
import Loader from '../../../components/loader/loader';
import { migrateBillSummaryData } from '../ebqUtils';
import NewModal from '../../../components/modals/NewModal';
import SummaryBillsItems from '../summaryBillsItems';
import SummaryBillSummary from '../summaryBillSummary';
import EbqSummaryPdf from '../ebqSummaryPdf';
import ArrowRightIcon from '../../../assets/svg-icons/ArrowRightIcon';

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unescaped-entities */

function billSummary() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const pdf = true;
  const isPreview = pathname?.split('/')[3] === 'preview';
  const [savePopUp, setSavePopUp] = useState('');
  const [modalbodyMsg, setModalBodyMsg] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const {
    summaryBillData,
    summaryBillWorkData,
    summarybillTotalPrice,
    isBillSummaryCreateSuccess,
    isBillSummaryCreateError,
    singleBillSummary,
    isBillGetLoading,
    isBillSummaryPrint,
  } = useSelector((state) => state.SOR);

  useEffect(() => {
    if (id) {
      dispatch(getBillsSummaryById(id));
    }
  }, [id]);

  useEffect(() => {
    if (singleBillSummary) {
      migrateBillSummaryData(singleBillSummary, dispatch);
    }
  }, [singleBillSummary]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    onAfterPrint: () => {
      dispatch(resetBillSummaryState());
    },
  });

  const validateFields = () => {
    const newErrors = {};
    const isValid = (fields) => Object.keys(fields).length === 0;
    if (!summaryBillWorkData.title) newErrors.title = true;
    if (!summaryBillWorkData.jobNo) newErrors.jobNo = true;
    
    dispatch(setWorkDataErrors(newErrors));
    return isValid(newErrors);
  };

  const SaveDraft = () => {
    setSavePopUp('Draft');
    setModalBodyMsg('You can still edit the bill when you save it as a draft.');
    setModalTitle('Save as draft?');
  };
  const SavePublished = () => {
    dispatch(setIsBillSummaryPrint(true));
    setSavePopUp('Published');
    setModalBodyMsg(
      "Once published, you can't edit the form anymore. To make changes, you will need to create a new bill.",
    );
    setModalTitle('Publish the bill?');
  };

  const closePopUp = () => {
    setSavePopUp('');
    setModalBodyMsg('');
    setModalTitle('');
  };

  const handleSave = () => {
    const objData = {
      work_title: summaryBillWorkData.title,
      technician_name: summaryBillWorkData.technicianName,
      contractor_name: summaryBillWorkData.contractorName,
      no_vendor: summaryBillWorkData.noVendor,
      period_start: summaryBillWorkData.startDate || null,
      period_end: summaryBillWorkData.endDate || null,
      job_num: summaryBillWorkData.jobNo,
      purchase_order_number: summaryBillWorkData.purchaseOrderNumber,
      bill_summary_items: summaryBillData?.map((item) => {
        return {
          bill: id ? item.bill : item.id,
          total_purchase_order: item.grand_total_first,
          total_approved_claims: item.grand_total_second,
          percentage_approved_claims: Math.round(
            (+item?.grand_total_second / +item?.grand_total_first) * 100,
          ),
        };
      }),
      total_price_first: (+summarybillTotalPrice?.summaryBillTotalOrder).toFixed(2),
      total_price_second: (+summarybillTotalPrice?.summaryBillTotalVendor).toFixed(2),
      total_purchase_order: (+summarybillTotalPrice?.summaryBillTotalOrder).toFixed(2),
      total_approved_claims: (+summarybillTotalPrice?.summaryBillTotalVendor).toFixed(2),
      status: 'draft',
    };
    if (savePopUp === 'Published') {
      objData.status = 'published';
      if (!validateFields()) {
        toast.error('Please fill the required fields and try again', {
          toastId: 'integrationSyncStarted',
        });
        closePopUp();
        dispatch(setIsBillSummaryPrint(false));
        return;
      }
    }
    if (savePopUp === 'Draft') {
      if (!validateFields()) {
        toast.error('Please fill Work title and Job no fields and try again', {
          toastId: 'integrationSyncStarted',
        });
        closePopUp();
        dispatch(setIsBillSummaryPrint(false));
        return;
      }
    }
    if (id) {
      dispatch(updateBillSummary({ id, ...objData }));
    } else {
      dispatch(createBillSummary(objData));
    }
  };

  useEffect(() => {
    if (isBillSummaryCreateSuccess) {
      if (isBillSummaryPrint) {
        handlePrint();
      }
      dispatch(setSORTab('bos'));
      history.push(`/ebq`);
      dispatch(resetBillSummaryState());
      dispatch(reset());
      closePopUp();
    } else if (isBillSummaryCreateError) {
      toast.error(isBillSummaryCreateError, {
        toastId: 'integrationSyncStarted',
      });
      dispatch(setIsBillSummaryPrint(false));
      closePopUp();
      dispatch(reset());
    }
  }, [isBillSummaryCreateSuccess, isBillSummaryCreateError]);

  return isBillGetLoading ? (
    <Loader />
  ) : (
    <>
      {savePopUp && (
        <NewModal
          showModal={savePopUp}
          handleClose={() => setSavePopUp('')}
          handleCreateSave={handleSave}
          primaryButtonText="Save"
          modalTitle={modalTitle}
        >
          {modalbodyMsg}
        </NewModal>
      )}
      <div style={isPreview ? { display: 'none' } : {}} className="p-2">
        {summaryBillData?.length > 0 && singleBillSummary?.status !== 'published' ? (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '10px',
                marginLeft: '10px',
              }}
            >
              <button type="button">
                <Link
                  to="/ebq"
                  style={{ color: '#727478' }}
                  onClick={() => dispatch(setSORTab('boq'))}
                >
                  Bill of quantity
                </Link>
              </button>
              <ArrowRightIcon />
              <button type="button" onClick={() => dispatch(setSORTab('bos'))}>
                <Link to="/ebq" style={{ color: '#36383C', fontWeight: '600' }}>
                  Bill summary
                </Link>
              </button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <h5 className="ml-2 mb-0">Bills summary</h5>
              {singleBillSummary?.status === 'draft' && (
                <button
                  className="new-btn"
                  style={{
                    backgroundColor: '#72747829',
                    borderColor: 'white',
                    width: '70px',
                    padding: '4px 8px',
                  }}
                  type="button"
                >
                  Draft
                </button>
              )}
            </div>
            <WorkData billSummary />
            <hr />
            <SummaryBillsItems />
            <hr />
            <SummaryBillSummary />
            <div
              style={{
                marginBottom: '100px',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}
            >
              <button
                type="button"
                title="Draft"
                onClick={SaveDraft}
                className="new-btn secondary-btn mr-2"
                style={{ color: '#36383C' }}
              >
                Save as draft
              </button>
              <button
                type="button"
                title="Publish"
                onClick={SavePublished}
                className="new-btn primary-btn"
              >
                Publish
              </button>
            </div>
          </>
        ) : (
          <div className="bill-no-items-container">
            <div className="bill-no-items-content">
              <h1 className="bill-no-items-title">
                {singleBillSummary?.status === 'published'
                  ? 'This bill summary is already published'
                  : "There's no items on the bill summary"}
              </h1>
              <Link to="/ebq">
                <button
                  type="button"
                  className="bill-no-items-button"
                  onClick={() => dispatch(setSORTab('bos'))}
                >
                  Back to Bill Summary Page
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
      <EbqSummaryPdf isPreview={isPreview} ref={componentRef} pdf={pdf} />
    </>
  );
}

export default billSummary;
