import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../components/form/form';
import DatepickerInput from '../../../../components/input/datepickerInput';
import TimeInput from '../../../../components/input/timeInput';
import FormPreviewHeader from '../FormPreview/FormPreviewHeader';
import { cn } from '../../../../utils/utilClassNames';

export default function FormSetDueDate({ isEdit }) {
  const form = useFormContext();

  return (
    <div>
      <FormPreviewHeader />

      <div className="bg-white rounded-2xl py-6 px-10 mx-auto space-y-4 mt-6">
        <div className="bg-neutral-color-aplha-neutral-8 rounded-[8px] px-2 py-3">
          <p className="font-semibold text-lg text-neutral-color-darker mb-0">
            Enter the start date and expected end date of your work
          </p>
        </div>

        <FormLabel isRequired>Start date/time</FormLabel>

        <div className="flex gap-2 w-full items-start">
          <FormField
            control={form.control}
            name="start_date"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormControl>
                  <DatepickerInput
                    showSuffix
                    suffixClassName="text-neutral-color-dark"
                    className={cn('flex-1 w-full', isEdit ? '' : '!bg-[#FFFFFF]')}
                    onChange={field.onChange}
                    placeholder="Not set"
                    value={field.value ? new Date(field.value) : undefined}
                    disabled={isEdit}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="start_time"
            render={({ field }) => (
              <FormItem className="min-w-[132px] mb-0.5">
                <FormControl>
                  <TimeInput onChange={field.onChange} value={field.value} disabled={isEdit} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex gap-2 w-full items-end">
          <FormField
            control={form.control}
            name="end_date"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel isRequired>End date/time</FormLabel>

                <FormControl>
                  <DatepickerInput
                    showSuffix
                    suffixClassName="text-neutral-color-dark"
                    className={cn('flex-1 w-full', isEdit ? '' : '!bg-[#FFFFFF]')}
                    onChange={field.onChange}
                    placeholder="Not set"
                    value={field.value ? new Date(field.value) : undefined}
                    disabled={isEdit}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="end_time"
            render={({ field }) => (
              <FormItem className="min-w-[132px] mb-0.5">
                <FormControl>
                  <TimeInput onChange={field.onChange} value={field.value} disabled={isEdit} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  );
}
