import React, { useState } from 'react'

function DropArea({onDrop}) {
    const [showDrop, setshowDrop] = useState(false)
    return (
        <section
            className= {showDrop  ? 'drop-area' : "hide-drop-area"}
            onDragEnter={()=> setshowDrop(true)}
            onDragLeave={()=> setshowDrop(false)}
            onDrop={()=>{
                onDrop()
                setshowDrop(false)
            }}
            onDragOver={e => e.preventDefault()}
        >
            Drop Here
        </section>
    )
}

export default DropArea