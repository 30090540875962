import React from 'react';
import { useSelector } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/dialog/dialog';
import Button from '../../../../components/button/button';
import TextInput from '../../../../components/input/textInput';
import { FRONT_URL } from '../../../../utils/axios';

export default function ShareFormModal({ open, onClose }) {
  const { selectedFormId } = useSelector((state) => state.form);

  const url = `${FRONT_URL}forms/submit-form/${selectedFormId}/detail`;


  const handleCopy = () => {
    navigator.clipboard.writeText(url);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Share this form?</DialogTitle>
          <DialogDescription>
            <TextInput disabled value={url} />
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2">
          <Button className="w-[100px]" size="large" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button className="w-[100px]" size="large" onClick={handleCopy}>
            Copy
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
