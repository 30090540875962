import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { tabs } from '../utils/navData';
import useAccessControl from './useAccessControl';
/* eslint-disable camelcase */

export default function useTabsForPermisions() {
  const [tabsForPermisions, setTabsForPermisions] = useState([]);
  const { profile } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.user);

  const [filteredTabs, setfilteredTabs] = useState([]);

  const { canViewAsset, canViewForm, canViewReminder, canViewEpq } = useAccessControl();

  useEffect(() => {
    if (user && profile) {
      const filteredArray = [];
      if (canViewAsset) {
        const ftabs = tabs?.filter(
          (tab) =>
            tab.id === 1 ||
            tab.id === 2 ||
            tab.id === 6 ||
            tab.id === 7 ||
            tab.id === 9 ||
            tab.id === 3 ||
            tab.id === 4 ||
            tab.id === 5 ||
            tab.id === 11 ||
            tab.id === 10 ||
            tab.id === 12 ||
            tab.id === 13,
        );

        filteredArray.push(...ftabs);
      }

      if (canViewEpq) {
        const ftabs = tabs?.filter((tab) => tab.id === 16);
        filteredArray.push(...ftabs);
      }
      if (canViewForm) {
        const ftabs = tabs?.filter((tab) => tab.id === 15);
        filteredArray.push(...ftabs);
      }
      if (canViewReminder) {
        const ftabs = tabs?.filter((tab) => tab.id === 14);
        filteredArray.push(...ftabs);
      }

      setfilteredTabs(filteredArray);
    }
  }, [user, profile]);

  useEffect(() => {
    if (user) {
      if (user?.role === 'CliAdm') {
        setTabsForPermisions(filteredTabs);
      } else if (user?.role === 'Requester') {
        setTabsForPermisions(
          filteredTabs?.filter((tab) => (tab.id === 2 || tab.id === 4) && tab.id !== 16),
        );
      } else if (user?.role === 'Manager') {
        setTabsForPermisions(filteredTabs?.filter((tab) => tab.id !== 11 && tab.id !== 12));
      } else if (user?.role === 'Technical Team') {
        setTabsForPermisions(
          filteredTabs?.filter(
            (tab) => tab.id !== 11 && tab.id !== 12 && tab.id !== 13 && tab.id !== 15,
          ),
        );
      }
    }
  }, [filteredTabs]);

  return {
    tabsForPermisions,
    canViewEpq,
    canViewForm,
    canViewReminder,
    canViewAsset,
  };
}
