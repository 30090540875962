/* eslint-disable react/no-array-index-key */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { format } from 'date-fns';

import foxLogo from '../../../assets/img/FOX-Logo3.png';
import { FieldType } from '../config/forms/constant';
import FilledStarIcon from '../../../assets/img/Icon.png';
import OutlineStarIcon from '../../../assets/img/OutlineStarIcon.png';

const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E1E1E1',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E1E1E1',
    paddingVertical: 16,
    paddingHorizontal: 8,
  },
  tableData: {
    width: '85%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E1E1E1',
    paddingVertical: 16,
    paddingHorizontal: 8,
  },
  tableColInfo: {
    width: '50%',
  },
  tableDataInfo: {
    width: '50%',
  },
  tableCell: {
    fontSize: 12,
    color: '#36383C',
    textAlign: 'left',
  },
});

export default function RequestResponsePdf({
  permitId,
  companyName,
  requestedBy,
  creationDate,
  formName,
  sections,
  requesterSignature,
  approversSignatures,
  qrCode,
  approvedDate,
  approvers,
  status,
  startDate,
  endDate,
  extensionDate,
  cancelAction,
  extendAction,
  hasDueDate,
  companyLogo,
  cancelReason,
  extendReason,
}) {
  const renderValue = (value, type) => {
    if (
      type === FieldType.ShortText ||
      type === FieldType.LongText ||
      type === FieldType.Checkboxes ||
      type === FieldType.Dropdown ||
      type === FieldType.MultipleChoices
    ) {
      return Array.isArray(value) ? value.join(', ') : value;
    } else if (type === FieldType.Date) {
      return value ? format(new Date(value), 'MMM d, yyyy') : '-';
    } else if (type === FieldType.Time) {
      const date = new Date();
      const time = value.split(':');
      date.setHours(time[0], time[1], 0);

      // Format the date to include AM/PM
      const formattedTime = format(date, 'hh:mm a');

      return formattedTime;
    }

    return value;
  };

  return (
    <Document>
      <Page size={{ width: 1092, height: 1783 }} style={styles.page}>
        <View style={styles.section}>
          {companyLogo ? (
            <Image src={companyLogo} style={{ width: 120 }} />
          ) : (
            <View style={{ width: 120 }} />
          )}
          <View style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <Text
              style={{
                fontWeight: 600,
                fontSize: 32,
                color: '#36383C',
                textAlign: 'center',
              }}
            >
              {companyName}
            </Text>

            <Text
              style={{
                fontWeight: 400,
                fontSize: 14,
                color: '#727478',
                textAlign: 'center',
                marginTop: 8,
              }}
            >
              Permit ID : {permitId}
            </Text>
          </View>
          <Image src={qrCode} style={{ width: 100, height: 100 }} />
        </View>

        <View style={{ ...styles.table, marginTop: 30 }}>
          {/* Header Row */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Form Name</Text>
            </View>
            <View style={styles.tableData}>
              <Text
                style={{
                  ...styles.tableCell,
                  fontWeight: 600,
                }}
              >
                {formName}
              </Text>
            </View>
          </View>

          {/* Data Row */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Status</Text>
            </View>
            <View
              style={[
                styles.tableData,
                {
                  width: '35%',
                },
              ]}
            >
              <Text style={styles.tableCell}>{status}</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Creation date</Text>
            </View>
            <View
              style={[
                styles.tableData,
                {
                  width: '35%',
                },
              ]}
            >
              <Text style={styles.tableCell}>{creationDate}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Requester</Text>
            </View>
            <View
              style={[
                styles.tableData,
                {
                  width: '35%',
                },
              ]}
            >
              <Text style={styles.tableCell}>{requestedBy}</Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Date Approved</Text>
            </View>
            <View
              style={[
                styles.tableData,
                {
                  width: '35%',
                },
              ]}
            >
              <Text style={styles.tableCell}>{approvedDate}</Text>
            </View>
          </View>

          {hasDueDate && (
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Start date/time</Text>
              </View>
              <View
                style={[
                  styles.tableData,
                  {
                    width: '35%',
                  },
                ]}
              >
                <Text style={styles.tableCell}>{startDate}</Text>
              </View>

              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>End date/time</Text>
              </View>
              <View
                style={[
                  styles.tableData,
                  {
                    width: '35%',
                  },
                ]}
              >
                <Text style={styles.tableCell}>{endDate}</Text>
              </View>
            </View>
          )}

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Extension date/time</Text>
            </View>
            <View style={styles.tableData}>
              <Text
                style={{
                  ...styles.tableCell,
                  fontWeight: 600,
                }}
              >
                {extensionDate}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ ...styles.table, marginTop: 20 }}>
          {sections?.map((section, idx) => {
            return (
              <React.Fragment key={idx}>
                <View style={styles.tableRow}>
                  <View
                    style={{
                      backgroundColor: '#72747814',
                      width: '100%',
                      padding: '12px 8px',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '18px',
                        fontWeight: 600,
                      }}
                    >
                      {section.title}
                    </Text>
                  </View>
                </View>

                {section.field_responses?.map((field) => {
                  if (field.field_type === FieldType.Image) {
                    return (
                      <View>
                        <View style={styles.tableRow}>
                          <View style={[styles.tableCol, styles.tableColInfo]}>
                            <Text style={styles.tableCell}>{field.field_label}</Text>
                          </View>
                          <View style={[styles.tableData, styles.tableDataInfo]}>
                            <Text style={styles.tableCell}>2 Photo</Text>
                          </View>
                        </View>

                        <View style={styles.tableRow}>
                          <View style={[styles.tableCol, styles.tableColInfo]}>
                            <Text style={styles.tableCell}>Photo name</Text>
                          </View>
                          <View style={[styles.tableData, styles.tableDataInfo]}>
                            <Text style={styles.tableCell}>Photo 1</Text>
                          </View>
                        </View>

                        <View
                          style={[
                            styles.tableRow,
                            {
                              padding: 10,
                            },
                          ]}
                        >
                          <Image
                            src={foxLogo}
                            style={{
                              height: 200,
                              width: 200,
                              borderRadius: 12,
                              border: '1px solid #E1E1E1',
                            }}
                          />
                        </View>
                      </View>
                    );
                  }

                  if (field.field_type === FieldType.Table) {
                    const columns = Object.keys(field.value[0]);
                    return (
                      <>
                        <View style={styles.tableRow}>
                          <View
                            style={{
                              backgroundColor: '#FFFFFF',
                              width: '100%',
                              padding: '12px 8px',
                            }}
                          >
                            <Text
                              style={{
                                fontSize: '14px',
                                fontWeight: 400,
                              }}
                            >
                              {field.field_label}
                            </Text>
                          </View>
                        </View>

                        <View>
                          <View
                            style={[
                              styles.tableRow,
                              {
                                backgroundColor: '#72747814',
                              },
                            ]}
                          >
                            {columns?.map((column, columnIdx) => {
                              return (
                                <View
                                  key={columnIdx}
                                  style={[styles.tableCol, styles.tableColInfo]}
                                >
                                  <Text style={styles.tableCell}>{column}</Text>
                                </View>
                              );
                            })}
                          </View>

                          {field.value.map((row) => {
                            return (
                              <View style={styles.tableRow}>
                                {columns.map((column, columnIdx) => {
                                  return (
                                    <View
                                      key={columnIdx}
                                      style={[styles.tableCol, styles.tableColInfo]}
                                    >
                                      <Text style={styles.tableCell}>{row[column]}</Text>
                                    </View>
                                  );
                                })}
                              </View>
                            );
                          })}
                        </View>
                      </>
                    );
                  }

                  return (
                    <View style={styles.tableRow}>
                      <View style={[styles.tableCol, styles.tableColInfo]}>
                        <Text style={styles.tableCell}>{field.field_label}</Text>
                      </View>
                      <View style={[styles.tableData, styles.tableDataInfo]}>
                        {(() => {
                          if (field.field_type === FieldType.Rating) {
                            return (
                              <View
                                style={{
                                  flexDirection: 'row',
                                  gap: 14,
                                }}
                              >
                                {' '}
                                {Array.from({ length: 5 }, (_, i) => i + 1).map(
                                  (value, ratingIdx) => {
                                    return (
                                      <View
                                        key={ratingIdx}
                                        style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                                      >
                                        <Image
                                          src={ratingIdx < 3 ? FilledStarIcon : OutlineStarIcon}
                                          style={{ width: 12, height: 12 }}
                                        />
                                        <Text style={{ fontSize: 10, color: '#36383C' }}>
                                          {ratingIdx + 1}
                                        </Text>
                                      </View>
                                    );
                                  },
                                )}
                              </View>
                            );
                          } else {
                            return (
                              <View>
                                <Text
                                  style={{
                                    ...styles.tableCell,
                                    fontWeight: 600,
                                  }}
                                >
                                  {renderValue(field.value, field.field_type)}
                                </Text>
                              </View>
                            );
                          }
                        })()}
                      </View>
                    </View>
                  );
                })}
              </React.Fragment>
            );
          })}
        </View>

        <View style={{ ...styles.table, marginTop: 20 }}>
          <View style={styles.tableRow}>
            <View
              style={{
                backgroundColor: '#72747814',
                width: '100%',
                padding: '12px 8px',
              }}
            >
              <Text
                style={{
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                Form Extension
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View
              style={[
                styles.tableCol,
                styles.tableColInfo,
                {
                  width: '25%',
                },
              ]}
            >
              <Text style={styles.tableCell}>Action By</Text>
            </View>
            <View
              style={[
                styles.tableData,
                styles.tableDataInfo,
                {
                  width: '25%',
                },
              ]}
            >
              <View>
                <Text
                  style={{
                    ...styles.tableCell,
                    fontWeight: 600,
                  }}
                >
                  {extendAction?.fullname || '-'}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableCol,
                styles.tableColInfo,
                {
                  width: '25%',
                },
              ]}
            >
              <Text style={styles.tableCell}>Designation</Text>
            </View>
            <View
              style={[
                styles.tableData,
                styles.tableDataInfo,
                {
                  width: '25%',
                },
              ]}
            >
              <View>
                <Text
                  style={{
                    ...styles.tableCell,
                    fontWeight: 600,
                  }}
                >
                  {extendAction?.role || '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View
              style={[
                styles.tableCol,
                styles.tableColInfo,
                {
                  width: '25%',
                },
              ]}
            >
              <Text style={styles.tableCell}>Signature</Text>
            </View>
            <View
              style={[
                styles.tableData,
                styles.tableDataInfo,
                {
                  width: '25%',
                },
              ]}
            >
              <View>
                {extendAction?.approver_signature ? (
                  <Image
                    src={extendAction?.approver_signature}
                    style={{
                      height: 86,
                      width: 171,
                      marginBottom: '10px',
                    }}
                  />
                ) : (
                  <Text
                    style={{
                      ...styles.tableCell,
                      fontWeight: 600,
                    }}
                  >
                    -
                  </Text>
                )}
              </View>
            </View>
            <View
              style={[
                styles.tableCol,
                styles.tableColInfo,
                {
                  width: '25%',
                },
              ]}
            >
              <Text style={styles.tableCell}>Date/time</Text>
            </View>
            <View
              style={[
                styles.tableData,
                styles.tableDataInfo,
                {
                  width: '25%',
                },
              ]}
            >
              <View>
                <Text
                  style={{
                    ...styles.tableCell,
                    fontWeight: 600,
                  }}
                >
                  {extendAction?.timestamp
                    ? format(new Date(extendAction?.timestamp), 'MMM d, yyyy')
                    : '-'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View
              style={[
                styles.tableCol,
                styles.tableColInfo,
                {
                  width: '25%',
                },
              ]}
            >
              <Text style={styles.tableCell}>Reason</Text>
            </View>
            <View
              style={[
                styles.tableData,
                styles.tableDataInfo,
                {
                  width: '75%',
                },
              ]}
            >
              <View>
                <Text
                  style={{
                    ...styles.tableCell,
                    fontWeight: 600,
                  }}
                >
                  {extendReason || '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {hasDueDate && (
          <View style={{ ...styles.table, marginTop: 20 }}>
            <View style={styles.tableRow}>
              <View
                style={{
                  backgroundColor: '#72747814',
                  width: '100%',
                  padding: '12px 8px',
                }}
              >
                <Text
                  style={{
                    fontSize: '18px',
                    fontWeight: 600,
                  }}
                >
                  Form cancellation / closing
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  styles.tableColInfo,
                  {
                    width: '25%',
                  },
                ]}
              >
                <Text style={styles.tableCell}>Action By</Text>
              </View>
              <View
                style={[
                  styles.tableData,
                  styles.tableDataInfo,
                  {
                    width: '25%',
                  },
                ]}
              >
                <View>
                  <Text
                    style={{
                      ...styles.tableCell,
                      fontWeight: 600,
                    }}
                  >
                    {cancelAction?.fullname || '-'}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableCol,
                  styles.tableColInfo,
                  {
                    width: '25%',
                  },
                ]}
              >
                <Text style={styles.tableCell}>Designation</Text>
              </View>
              <View
                style={[
                  styles.tableData,
                  styles.tableDataInfo,
                  {
                    width: '25%',
                  },
                ]}
              >
                <View>
                  <Text
                    style={{
                      ...styles.tableCell,
                      fontWeight: 600,
                    }}
                  >
                    {cancelAction?.role || '-'}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  styles.tableColInfo,
                  {
                    width: '25%',
                  },
                ]}
              >
                <Text style={styles.tableCell}>Signature</Text>
              </View>
              <View
                style={[
                  styles.tableData,
                  styles.tableDataInfo,
                  {
                    width: '25%',
                  },
                ]}
              >
                <View>
                  {cancelAction?.approver_signature ? (
                    <Image
                      src={cancelAction?.approver_signature}
                      style={{
                        height: 86,
                        width: 171,
                        marginBottom: '10px',
                      }}
                    />
                  ) : (
                    <Text
                      style={{
                        ...styles.tableCell,
                        fontWeight: 600,
                      }}
                    >
                      -
                    </Text>
                  )}
                </View>
              </View>
              <View
                style={[
                  styles.tableCol,
                  styles.tableColInfo,
                  {
                    width: '25%',
                  },
                ]}
              >
                <Text style={styles.tableCell}>Date/time</Text>
              </View>
              <View
                style={[
                  styles.tableData,
                  styles.tableDataInfo,
                  {
                    width: '25%',
                  },
                ]}
              >
                <View>
                  <Text
                    style={{
                      ...styles.tableCell,
                      fontWeight: 600,
                    }}
                  >
                    {cancelAction?.timestamp
                      ? format(new Date(cancelAction?.timestamp), 'MMM d, yyyy')
                      : '-'}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCol,
                  styles.tableColInfo,
                  {
                    width: '25%',
                  },
                ]}
              >
                <Text style={styles.tableCell}>Reason</Text>
              </View>
              <View
                style={[
                  styles.tableData,
                  styles.tableDataInfo,
                  {
                    width: '75%',
                  },
                ]}
              >
                <View>
                  <Text
                    style={{
                      ...styles.tableCell,
                      fontWeight: 600,
                    }}
                  >
                    {cancelReason || '-'}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}

        <View
          style={{
            marginVertical: '20px',
            height: '1px',
            width: '100%',
            backgroundColor: '#E1E1E1',
          }}
        />

        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 15 }}>
          <View>
            <Text
              style={{
                color: '#36383C',
                fontSize: '14px',
                marginBottom: '16px',
              }}
            >
              Requester
            </Text>
            {requesterSignature && (
              <Image
                src={requesterSignature}
                style={{
                  height: 86,
                  width: 171,
                  marginBottom: '10px',
                }}
              />
            )}

            <View
              style={{
                borderTop: '1px solid #E1E1E1',
                paddingTop: '10px',
              }}
            >
              <Text
                style={{
                  color: '#36383C',
                  fontSize: '14px',
                }}
              >
                {requestedBy || ''}
              </Text>
              <Text
                style={{
                  color: '#36383C',
                  fontSize: '14px',
                }}
              >
                Date: {creationDate}
              </Text>
            </View>
          </View>

          <View>
            <Text
              style={{
                color: '#36383C',
                fontSize: '14px',
                marginBottom: '16px',
              }}
            >
              Approver(s) :
            </Text>
            {approversSignatures?.map((signature) => {
              return (
                <Image
                  src={signature.signature}
                  style={{
                    height: 86,
                    width: 171,
                    marginBottom: '10px',
                  }}
                />
              );
            })}

            <View
              style={{
                borderTop: '1px solid #E1E1E1',
                paddingTop: '10px',
              }}
            >
              <Text
                style={{
                  color: '#36383C',
                  fontSize: '14px',
                }}
              >
                {approvers.join(',')}
              </Text>
              <Text
                style={{
                  color: '#36383C',
                  fontSize: '14px',
                }}
              >
                Date: {approvedDate}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
