import QRCode from 'qrcode';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import NotFound from '../../components/notFounPage/notFound';
import { getFormDetailByIdWithoutAuth } from '../../redux/features/forms/formSlice';
import { FRONT_URL } from '../../utils/axios';
import FormQR from './components/Form/FormQR';
import FormPreviewHeader from './components/FormPreview/FormPreviewHeader';

export default function FormSubmitQR() {
  const { id } = useParams();

  const [qrCodeUrl, setQrCodeUrl] = React.useState('');

  const { form } = useSelector((state) => state.form);

  const getQrCode = async () => {
    const url = `${FRONT_URL}forms/submit-form/${id}/detail`;
    const result = await QRCode.toDataURL(url);
    setQrCodeUrl(result);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getQrCode();
    dispatch(getFormDetailByIdWithoutAuth(id));
  }, [id]);

  if (form.status === 'closed') {
    return <NotFound />;
  }

  return (
    <div className="my-[73px] bg-lightGray max-w-[848px] mx-auto p-6 h-full shadow-sm space-y-6">
      <FormPreviewHeader />

      <FormQR qrCodeUrl={qrCodeUrl} />
    </div>
  );
}
