import React, { useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBillData,
  setBillDataOnPopup,
  setOpenAddItemsModal,
} from '../../redux/features/SOR/SORSlice';
import SOR from './Pages/SOR';

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-globals */

function AddBillItemsModal() {
  const { billData, openAddItemsModal, billDataOnPopup } = useSelector((state) => state.SOR);
  const dispatch = useDispatch();

  const fromBillPopup = true;

  useEffect(() => {
    dispatch(setBillDataOnPopup(billData));
  }, []);

  const close = () => {
    dispatch(setOpenAddItemsModal(false));
  };

  const handleSave = () => {
    // const updatedArray = billDataOnPopup.map((item) => {
    //   const matchingElement = billData.find((ele) => ele.id === item.id);
    //   return matchingElement || item;
    // });
    dispatch(setBillData(billDataOnPopup));
    close();
  };

  return (
    <Drawer
      anchor="right"
      open={openAddItemsModal}
      onClose={close}
      PaperProps={{
        style: {
          width: '95%',
          height: '100%',
        },
      }}
    >
      <div className=" w-100">
        <div className="col-lg-12 col-sm-12 pb-5">
          <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
            <div className="col-12 row px-2 ">
              <div className="my-auto">
                <b>Add item</b>
              </div>
              <div className="row ml-auto">
                <button
                  type="button"
                  className="btn tertiary-btn ml-2"
                  data-dismiss="modal"
                  onClick={close}
                >
                  <div className="fas fa-times" />
                </button>
              </div>
            </div>
          </div>
          <SOR
            billData={billDataOnPopup}
            setBillData={setBillDataOnPopup}
            fromBillPopup={fromBillPopup}
          />
        </div>
        <div className=" m-0 p-2 page-profile-footer">
          <button onClick={handleSave} className="new-btn primary-btn" type="submit">
            Add items
          </button>
        </div>
      </div>
    </Drawer>
  );
}

export default AddBillItemsModal;
