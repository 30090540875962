/* eslint-disable prefer-rest-params */
/* eslint-disable import/prefer-default-export */
export async function smartTryCatchV2(callback, callbackParams, rejectionObject) {
  try {
    return await callback(arguments.length === 3 ? callbackParams : null);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.error) ||
      error.message ||
      error.toString();

    if (rejectionObject) {
      return arguments.length === 3
        ? rejectionObject.rejectWithValue(message)
        : arguments[2].rejectWithValue(message);
    }
    return message;
  }
}
